<template>
  <div class="mt-5 h-full">
    <div class="tableOverflow scroltypey h-full">
      <div class="tableWidth h-full">
        <div class="tableContainer h-full">
          <div class="tableHead">
            <ul class="tableUl text-sm">
              <li class="w-[18%] pr-3">
                {{ $t("project.tableInterview.tableHead.candidate") }}
              </li>
              <li class="w-[18%] pr-2">
                {{
                  $t("project.tableInterview.tableHead.preliminaryInterview")
                }}
              </li>
              <li class="w-[15%] pr-2">
                {{ $t("project.tableInterview.tableHead.hrInterview") }}
              </li>
              <li class="w-[17%] pr-2">
                {{ $t("project.tableInterview.tableHead.technicalInterview") }}
              </li>
              <li class="w-[10%] pr-3">
                {{ $t("project.tableInterview.tableHead.assessment") }}
              </li>
              <li class="w-[22%] pl-1 text-left">
                {{ $t("project.tableInterview.tableHead.status") }}
              </li>
            </ul>
          </div>
          <div class="tableBody px-4 mb-2 scroltype">
            <div
              class="w-full textcol text-sm"
              style="height: calc(56vh - 1px)"
            >
              <draggable
                v-model="List"
                @change="changeOrderId(List)"
                @start="drag = true"
                @end="drag = false"
                :disabled="isDelete == 2"
              >
                <ul
                  v-for="(item, index) in List"
                  :key="index"
                  class="tableBodyUl cursor-pointer bg-white"
                >
                  <li
                    class="w-[18%] py-2.5 pr-3 text-sm flex gap-2 items-center"
                  >
                    {{ formatText(item.fullname) }}
                  </li>
                  <li
                    class="w-[18%] pr-2 pl-1 hover:text-blue-600 transition-all duration-200"
                    @click="currentStateId ? '' : changePoint(item, 1)"
                  >
                    {{ item.interviewPoint || 0 }}
                  </li>
                  <li
                    class="w-[15%] pr-2 pl-1 hover:text-blue-600 transition-all duration-200"
                    @click="currentStateId ? '' : changePoint(item, 2)"
                  >
                    {{ item.ikPoint || 0 }}
                  </li>
                  <li
                    class="w-[17%] pr-2 pl-1 hover:text-blue-600 transition-all duration-200"
                    @click="currentStateId ? '' : changePoint(item, 3)"
                  >
                    {{ item.technicalPoint || 0 }}
                  </li>
                  <li
                    class="w-[10%] pr-2 pl-1 hover:text-blue-600 transition-all duration-200"
                    @click="currentStateId ? '' : changePoint(item, 4)"
                  >
                    {{ item.assessmentPoint || 0 }}
                  </li>
                  <li
                    class="w-[22%] pr-2 text-xs pl-1 flex justify-between gap-3"
                  >
                    <div
                      class="text-xs flex items-center"
                      :class="stateName(item.stateId).class"
                    >
                      {{ stateName(item.stateId).name }}
                    </div>

                    <!-- {{ item }} -->
                    <div
                      class="flex items-center justify-end gap-4"
                      v-if="item.stateId != 8"
                    >
                      <!--*---------------- Özlük Bilgisi ----------------*-->
                      <!-- :disabled="currentStateId" -->
                      <asyncButton
                        v-if="item.stateId >= 5"
                        :disabled="currentStateId"
                        type="button"
                        @click="personelInformationMail(item)"
                        :hiddenText="true"
                        :buttonClass="
                          'w-full !px-0 !gap-0 ' +
                          (currentStateId && '!bg-transparent')
                        "
                        :imgSrc="require('@/assets/shortList/information.png')"
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        :imgClass="`!w-[18px] ${
                          item.isPersonal > 0 && 'opacity-30'
                        }`"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          item.isPersonal == 0
                            ? $t(
                                'project.tableInterview.tooltip.formSubmission'
                              )
                            : $t('project.tableInterview.tooltip.formSubmitted')
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
                        :spinnerClass="
                          '!border-t-transparent !border-black !text-black' +
                          (item.stateId == 5 && 'text-black text-opacity-30')
                        "
                        tooltipRotateClass="ml-[85px]"
                      />
                      <!--*---------------- Özlük Bilgisi ----------------*-->

                      <!--*---------------- Özlük Bilgisi Görüntüle ----------------*-->
                      <!-- :disabled="currentStateId" -->
                      <asyncButton
                        v-if="item.stateId >= 5"
                        type="button"
                        @click="$emit('showpersonelInformation', item)"
                        :hiddenText="true"
                        buttonClass="w-full !bg-transparent !px-0 !gap-0"
                        awesome="fa-solid fa-magnifying-glass text-xs font-semibold"
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        imgClass="w-5"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          $t(
                            'project.tableInterview.tooltip.viewPersonalInformation'
                          )
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
                        :spinnerClass="
                          '!border-t-transparent !border-black !text-black' +
                          (item.stateId == 5 && 'text-black text-opacity-30')
                        "
                        tooltipRotateClass="ml-[85px]"
                      />
                      <!--*---------------- Özlük Bilgisi Görüntüle ----------------*-->

                      <!--*---------------- Takvim ----------------*-->
                      <asyncButton
                        v-if="
                          item.stateId == 1 ||
                          item.stateId == 2 ||
                          item.stateId == 4 ||
                          item.stateId == 3
                        "
                        :disabled="item.stateId == 7 || currentStateId"
                        type="button"
                        @click="$emit('showDateModal', item)"
                        :hiddenText="true"
                        :buttonClass="
                          'w-full !px-0 !gap-0 ' +
                          (currentStateId && '!bg-transparent')
                        "
                        :awesome="
                          'fa-solid fa-calendar text-sm ' +
                          (currentStateId && '!text-gray-600')
                        "
                        containerBackground="!text-black md:!w-auto justify-center !w-full"
                        imgClass="w-5"
                        container-class="w-full md:w-auto"
                        :isTooltip="true"
                        :toolTipTitle="
                          $t('project.tableInterview.tooltip.createTechnical')
                        "
                        :tooltipTop="true"
                        toolTipClass="z-50 w-[120px] -right-3 !top-6 justify-end"
                        :spinnerClass="
                          '!border-t-transparent !border-black !text-black' +
                          (item.stateId == 5 && 'text-black text-opacity-30')
                        "
                        tooltipRotateClass="ml-[85px]"
                      />
                      <!--*---------------- Takvim ----------------*-->

                      <!--*---------------- Onayla ----------------*-->
                      <div class="flex gap-4 items-center">
                        <!-- {{ item.stateId == 1 }}
                        {{ isInterview(item) }} -->
                        <!-- :disabled="item.stateId == 1 && !isInterview(item)" -->
                        <!-- // !item.stateId == 1 -->
                        <asyncButton
                          v-if="isInterview(item) && !currentStateId"
                          type="button"
                          @click="workHired(item)"
                          :hiddenText="true"
                          :buttonClass="
                            'w-full !px-0 !gap-0 ' +
                            (currentStateId && '!bg-transparent')
                          "
                          :awesome="
                            'fa-solid fa-check font-bold text-green-700  ' +
                            (currentStateId && '!text-gray-600')
                          "
                          containerBackground="!bg-transparent !text-black md:!w-auto justify-center !w-full"
                          imgClass="w-5"
                          container-class="w-full md:w-auto"
                          :isTooltip="true"
                          :toolTipTitle="
                            $t('project.tableInterview.tooltip.confirm')
                          "
                          :tooltipTop="true"
                          toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                          spinnerClass="!border-t-transparent !border-black !text-black"
                          tooltipRotateClass="ml-4"
                        />
                        <!--*---------------- Onayla ----------------*-->

                        <!--*---------------- Reddet ----------------*-->
                        <asyncButton
                          v-if="
                            item.stateId != 2 &&
                            item.stateId != 1 && item.stateId != 7 &&
                            !currentStateId 
                          "
                          :disabled="
                            item.stateId == 2 ||
                            item.stateId == 1 ||
                            currentStateId
                          "
                          type="button"
                          @click="deleteItem(item)"
                          :hiddenText="true"
                          :buttonClass="
                            'w-full !px-0 !gap-0 ' +
                            (currentStateId && '!bg-transparent')
                          "
                          :awesome="
                            'fa-solid fa-xmark text-base ' +
                            (currentStateId
                              ? '!text-gray-600'
                              : '!text-red-600')
                          "
                          containerBackground="!text-black md:!w-auto justify-center !w-full !bg-transparent"
                          imgClass="w-5"
                          container-class="w-full md:w-auto"
                          :isTooltip="true"
                          :toolTipTitle="
                            $t('project.tableInterview.tooltip.reject')
                          "
                          :tooltipTop="true"
                          toolTipClass="z-50 w-[30px] right-0 !top-6 justify-end"
                          spinnerClass="!border-t-transparent !border-black !text-black"
                          tooltipRotateClass="ml-4"
                        />
                        <!--*---------------- Reddet ----------------*-->
                      </div>
                    </div>
                  </li>
                </ul>
              </draggable>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>

<script>
// networking
import { shortList, personelInformation } from "@/networking/urlmanager";

// global components
import draggable from "vuedraggable";
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";

import asyncButton from "@/components/button.vue";

import moment from "moment";
import "moment/locale/tr";

export default {
  name: "tableAsistan",
  components: {
    pagination,
    tableLoader,
    draggable,
    asyncButton,
  },
  data() {
    return {
      load: false,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,

      selectedItem: null,
      showModal: false,

      isDelete: 2,
      List: [],

      infoFormLoad: false,
      selectedIndex: null,
    };
  },
  computed: {
    currentStateId() {
      return this.$store.state.project.stateId == 3;
    },
  },
  methods: {
    stateName(val) {
      try {
        if (val == 1) {
          return {
            name: this.$t("project.tableInterview.stateName.waiting"),
            class: "",
          };
        } else if (val == 2) {
          return {
            name: this.$t("project.tableInterview.stateName.interviewSent"),
            class: "text-green-500",
          };
        } else if (val == 3) {
          return {
            name: this.$t("project.tableInterview.stateName.dateConfirmed"),
            class: "text-green-500",
          };
        } else if (val == 4) {
          return {
            name: this.$t("project.tableInterview.stateName.dateRejected"),
            class: "text-red-500",
          };
        } else if (val == 5) {
          return {
            name: this.$t("project.tableInterview.stateName.meetCreated"),
            class: "text-green-500",
          };
        } else if (val == 6) {
          return {
            name: this.$t("project.tableInterview.stateName.hired"),
            class: "text-green-500",
          };
        } else if (val == 7) {
          return {
            name: this.$t("project.tableInterview.stateName.inadequate"),
            class: "text-red-500",
          };
        } else {
          return { name: "", class: "" };
        }
      } catch {
        return { name: "", class: "" };
      }
    },
    isInterview(item) {
      if (
        item.interviewPoint >= 0 &&
        item.ikPoint > 0 &&
        item.technicalPoint > 0 &&
        item.assessmentPoint > 0 && item.stateId != 6 
      ) {
        return true;
      } else {
        return false;
      }
    },
    changeOrderId(list) {
      if (this.isDelete != 1) {
        let data = list.map((item, index) => {
          return {
            id: item.id,
            orderId: index + 1,
          };
        });
        this.axios
          .post(projectInterview.changeOrder, { list: data })
          .then(() => {})
          .catch((err) => {
            this.authController(err);
          });
      }
    },
    personelInformationMail(data) {
      this.verifiedMessage(
        data.fullname + ' '+
          this.$t(
            "project.tableInterview.verifiedMessage.candidateFormRequest"
          ),
        this.$t("project.tableInterview.verifiedMessage.confirm"),
        this.$t("project.tableInterview.verifiedMessage.decline")
      ).then((result) => {
        if (result.isConfirmed) {
          this.infoFormLoad = true;
          this.selectedIndex = data.id;
          this.axios
            .post(personelInformation.mail, {
              cvId: data.id,
              fullname: data.fullname,
              projectId: data.projectId,
              shortId: data.id,
              email: data.contactEmail,
            })
            .then((res) => {
              this.infoFormLoad = false;
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              this.infoFormLoad = false;
              this.authController(err);
            });
        }
      });
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(shortList.getAll, {
          params: {
            page: this.currentPage,
            projectId: this.$route.query.id,
            search: this.search,
          },
        })
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;
          this.List = detail;
          this.isDelete = detail.length > 0 ? detail[0].isDelete : 2;
          this.$emit("List", detail);
          this.$emit("isDelete", this.isDelete);
          this.load = false;
        })
        .catch((err) => {
          console.log(err);
          this.load = false;
          this.authController(err);
        });
    },
    deleteItem(item) {
      this.$swal({
        text: this.$t("project.tableInterview.verifiedMessage.cancelReason"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: this.$t(
          "project.tableInterview.verifiedMessage.cancelReasonConfirm"
        ),
        cancelButtonText: this.$t(
          "project.tableInterview.verifiedMessage.cancelReasonDecline"
        ),
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(shortList.delete, {
              rowId: item.id,
              projectId: this.$route.query.id,
              connectId: item.cvId,
              message: result.value,
              fullname: item.fullname,
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
              this.$emit("refresh", true);
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    workHired(item) {
      this.verifiedMessage(
        item.fullname + ' '+
          this.$t("project.tableInterview.verifiedMessage.workHiredText"),
        this.$t("project.tableInterview.verifiedMessage.workHireConfirm"),
        this.$t("project.tableInterview.verifiedMessage.workHireDecline")
      ).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(shortList.workHired, {
              rowId: item.id,
              projectId: this.$route.query.id,
              fullname: item.fullname,
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    changePoint(val, type) {
      this.$emit("changePoint", {
        detail: val,
        typeId: type,
      });
    },
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("noDate");
    },
  },
  watch: {
    currentPage() {
      this.getAll();
    },
  },
};
</script>
