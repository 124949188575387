<template>
  <modal
    name="change-score-project"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h4 class="text-lg">
        <b>{{ fullname }}</b>
        {{ title }}
      </h4>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="add()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="value"
          min="0"
          max="100"
          type="number"
          :required="true"
          placeholder="Puan"
          :input-class="[
            { 'bg-gray-300/2-10  cursor-not-allowed': isDisabled },
          ]"
          title="Puan"
          :disabled="isDisabled || statusId == 2"
        />

        <customInput
          v-model="note"
          min="0"
          max="1000"
          type="textarea"
          :required="true"
          placeholder="Not"
          title="Not"
          :textarea-class="[
            'h-32 resize-none',
            { 'bg-gray-300/10  cursor-not-allowed': isDisabled },
          ]"
          :disabled="isDisabled || statusId == 2"
        />

        <requiredField v-if="!isDisabled" />
        <div
          v-if="!isDisabled"
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :disabled="statusId == 2"
              name="Kaydet"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";
import { shortList } from "@/networking/urlmanager.js";

export default {
  name: "changeScoreAndNote",
  props: [
    "rowId",
    "title",
    "typeId",
    "fullname",
    "score",
    "defaultNote",
    "statusId",
    "isDisabled",
  ],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      value: "",
      note: "",
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.value = this.score ? this.score : 0;
        this.note = this.defaultNote ? this.defaultNote : "";
      }, 50);
      this.$modal.show("change-score-project");
    },
    hide() {
      this.$modal.hide("change-score-project");
    },
    add() {
      if (this.value) {
        this.load = true;

        if (this.statusId == 2) {
          this.warningMesage(
            "ADAY İŞE ALDINDIĞINDAN BU İŞLEM GERÇEKLEŞTİRİLEMEZ!"
          );
          return;
        }
        let url = shortList.setInterview;
        if (this.typeId == 2) url = shortList.setIK;
        if (this.typeId == 3) url = shortList.setTechnical;
        if (this.typeId == 4) url = shortList.setAssessment;

        this.axios
          .post(url, {
            rowId: this.rowId,
            projectId: this.$route.query.id,
            score: this.value,
            scoreNote: this.note,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            console.log(err);
            this.authController(err);
            this.load = false;
          });
      }
    },
  },
};
</script>
