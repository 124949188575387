var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"class-name space-y-5 p-5",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[(_vm.types.length === 0)?_c('div',{staticClass:"bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4"},[_c('p',{staticClass:"text-yellow-700"},[_c('i',{staticClass:"fa-solid fa-exclamation-triangle mr-2"}),_vm._v(" "+_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewType.noInterviewType" ))+" ")]),_c('router-link',{staticClass:"mt-2 inline-block bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition",attrs:{"to":"/setting"}},[_vm._v(" "+_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewType.goToSettings" ))+" ")])],1):[_c('customInput',{attrs:{"type":"text","min":3,"max":40,"required":true,"placeholder":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.interviewTitle'
        ),"title":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.interviewTitle'
        )},model:{value:(_vm.InterviewType.name),callback:function ($$v) {_vm.$set(_vm.InterviewType, "name", $$v)},expression:"InterviewType.name"}}),_c('customInput',{attrs:{"type":"select","selectList":_vm.types,"required":true,"placeholder":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.interviewType'
        ),"title":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.interviewType'
        )},model:{value:(_vm.InterviewType.type),callback:function ($$v) {_vm.$set(_vm.InterviewType, "type", $$v)},expression:"InterviewType.type"}}),_c('customInput',{attrs:{"type":"select","selectList":[
        {
          value: 2,
          name: _vm.$t(
            'project.projectUser.components.shortList.components.steps.interviewType.faceToface'
          ),
        },
        {
          value: 1,
          name: _vm.$t(
            'project.projectUser.components.shortList.components.steps.interviewType.online'
          ),
        },
      ],"required":true,"placeholder":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.interviewPlace'
        ),"title":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.interviewPlace'
        )},model:{value:(_vm.InterviewType.isOnline),callback:function ($$v) {_vm.$set(_vm.InterviewType, "isOnline", $$v)},expression:"InterviewType.isOnline"}}),(_vm.InterviewType.isOnline == 1)?_c('div',{staticClass:"w-full"},[_c('label',{staticClass:"textcol font-semibold flex gap-1 items-center"},[_vm._v(" "+_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewType.addAttendee" ))+" ")]),_c('userAddSelect',{on:{"emails-selected":_vm.handleSelectedEmails}})],1):_vm._e(),_c('asyncButton',{staticClass:"w-full",attrs:{"text":_vm.$t(
          'project.projectUser.components.shortList.components.steps.interviewType.next'
        ),"type":"submit","disabled":_vm.types.length === 0}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }