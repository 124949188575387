<template>
  <modal
    name="personel-information"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{
          $t(
            "project.projectUser.components.shortList.components.personelInformation.index.preliminaryInterviewAppointment"
          )
        }}
      </h1>
      <button @click.stop="hide">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div class="p-10 flex flex-col gap-5 max-sm:p-4">
      <card
        :url="passportPhoto"
        :load="load"
        :title="
          $t(
            'project.projectUser.components.shortList.components.personelInformation.index.card.passportPhoto'
          )
        "
      />
      <card
        :url="healthReport"
        :load="load"
        :title="
          $t(
            'project.projectUser.components.shortList.components.personelInformation.index.card.healthReport'
          )
        "
      />
      <card
        :url="diploma"
        :load="load"
        :title="
          $t(
            'project.projectUser.components.shortList.components.personelInformation.index.card.diploma'
          )
        "
      />
      <card
        :url="criminalRecordPaper"
        :load="load"
        :title="
          $t(
            'project.projectUser.components.shortList.components.personelInformation.index.card.criminalRecordPaper'
          )
        "
      />
      <card
        :url="certificateResidence"
        :load="load"
        :title="
          $t(
            'project.projectUser.components.shortList.components.personelInformation.index.card.certificateOfResidence'
          )
        "
      />
    </div>
  </modal>
</template>

<script>
import { personelInformation } from "@/networking/urlmanager";
import card from "./utility/card.vue";

export default {
  props: ["Detail"],
  name: "select-date-modal",
  components: {
    card,
  },
  data() {
    return {
      load: false,
      goLoad: false,

      certificateResidence: "",
      criminalRecordPaper: "",
      diploma: "",
      healthReport: "",
      passportPhoto: "",
    };
  },
  methods: {
    show() {
      setTimeout(() => {
      this.certificateResidence = null
      this.criminalRecordPaper = null
      this.diploma = null
      this.healthReport = null
      this.passportPhoto = null
        this.getAll();
      }, 50);
      this.$modal.show("personel-information");
    },
    hide() {
      this.$modal.hide("personel-information");
    },
    getAll() {
      this.load = true;
      this.axios
        .get(
          personelInformation.getAll +
            "?projectId=" +
            this.Detail.projectId +
            "&shortId=" +
            this.Detail.id
        )
        .then((res) => {
          const {
            certificateResidence,
            criminalRecordPaper,
            diploma,
            healthReport,
            passportPhoto,
          } = res.data.data;

          certificateResidence
            ? (this.certificateResidence = certificateResidence)
            : "";
          criminalRecordPaper
            ? (this.criminalRecordPaper = criminalRecordPaper)
            : "";
          diploma ? (this.diploma = diploma) : diploma;
          healthReport ? (this.healthReport = healthReport) : "";
          passportPhoto ? (this.passportPhoto = passportPhoto) : "";
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
};
</script>
