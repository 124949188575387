<template>
  <modal
    name="add-contact"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.information.contact.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="icon"
          type="select"
          :selectList="typeList"
          :required="true"
          :is-error="true"
          ref="icon"
          :placeholder="$t('cv.profile.information.contact.add.typePlacholder')"
          :title="$t('cv.profile.information.contact.add.type')"
        />

        <div v-if="icon == 4">
          <customInput
            v-model="type"
            type="text"
            min="2"
            max="25"
            :required="true"
            :is-error="true"
            ref="type"
            :placeholder="
              $t('cv.profile.information.contact.add.socialMediaNamePlacholder')
            "
            :title="$t('cv.profile.information.contact.add.socialMediaName')"
          />
        </div>
        <customInput
          v-if="icon"
          v-model="value"
          :type="isTypeValue"
          :required="true"
          :is-error="true"
          ref="value"
          :placeholder="$t('cv.profile.information.contact.add.contactAddress')"
          :title="
            (icon == 4
              ? $t('cv.profile.information.contact.add.socialMediaName')
              : contactName(icon)) +
            $t('cv.profile.information.contact.add.enter')
          "
        />

        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.information.contact.add.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";
import { contact } from "@/networking/urlmanager.js";

export default {
  name: "contact-add",
  props: ["isEdit", "contactName"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,
      type: "",
      icon: 0,
      value: "",
      typeList: [
        {
          value: 1,
          name: this.$t("cv.profile.information.contact.add.email"),
        },
        {
          value: 2,
          name: this.$t("cv.profile.information.contact.add.phone"),
        },
        {
          value: 3,
          name: this.$t("cv.profile.information.contact.add.adress"),
        },
        {
          value: 4,
          name: this.$t("cv.profile.information.contact.add.socialMedia"),
        },
        {
          value: 5,
          name: this.$t("cv.profile.information.contact.add.webSite"),
        },
      ],
    };
  },
  computed: {
    isTypeValue() {
      switch (Number(this.icon)) {
        case 1:
          return "email";
        case 2:
          return "number";
        case 3:
        case 4:
          return "text";
        case 5:
          return "url";
        default:
          return "text";
      }
    },
  },
  methods: {
    show() {
      this.resetForm();
      this.$modal.show("add-contact");
    },
    hide() {
      this.$modal.hide("add-contact");
      this.resetForm();
    },
    resetForm() {
      this.icon = "";
      this.type = "";
      this.value = "";
      if (this.$refs.icon) this.$refs.icon.clearError();
      if (this.$refs.type) this.$refs.type.clearError();
      if (this.$refs.value) this.$refs.value.clearError();
    },
    async validateForm() {
      let isValid = true;

      // Validate icon selection
      if (this.$refs.icon) {
        const iconValid = this.$refs.icon.validateInput();
        isValid = isValid && iconValid;
      }

      if (this.icon !== "") {
        // Validate social media type if needed
        if (this.icon === 4 && this.$refs.type) {
          const typeValid = this.$refs.type.validateInput();
          isValid = isValid && typeValid;
        }

        // Validate value field
        if (this.$refs.value) {
          const valueValid = this.$refs.value.validateInput();
          isValid = isValid && valueValid;

          // Additional type-specific validation
          if (valueValid && this.value) {
            switch (Number(this.icon)) {
              case 1: // Email
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(this.value)) {
                  this.$refs.value.setError(this.$t("validation.invalidEmail"));
                  isValid = false;
                }
                break;
              case 2: // Phone
                const phoneRegex = /^\+?[\d\s-]{8,}$/;
                if (!phoneRegex.test(this.value)) {
                  this.$refs.value.setError(this.$t("validation.invalidPhone"));
                  isValid = false;
                }
                break;
              case 5: // Website
                const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
                if (!urlRegex.test(this.value)) {
                  this.$refs.value.setError(this.$t("validation.invalidUrl"));
                  isValid = false;
                }
                break;
            }
          }
        }
      }

      return isValid;
    },
    async save() {
      this.load = true;
      const isValid = await this.validateForm();
      if (isValid && this.icon && this.value) {
        if (typeof this.value === "string") {
          this.value = this.value.trim();
        }

        this.$store.commit("addContact", {
          icon: this.icon,
          type: this.icon == 4 ? this.type : this.contactName(this.icon),
          value: this.value,
        });

        this.successMessage();
        this.hide();
      }

      this.load = false;
    },
    async add() {
      this.load = true;

      const isValid = await this.validateForm();
      if (isValid && this.icon && this.value) {
        if (typeof this.value === "string") {
          this.value = this.value.trim();
        }

        const list = [{
          icon: this.icon,
          type: this.icon == 4 ? this.type : this.contactName(this.icon),
          value: this.value,
        }];

        try {
          const response = await this.axios.post(contact.add, {
            cvId: this.$route.params.id,
            list: (list),
            isCostumAdd: true,
          });

          this.successMessage(response.data.message);
          this.$emit("refresh", true);
          this.hide();
        } catch (err) {
          this.authController(err);
        } finally {
          this.load = false;
        }
      } else {
        this.load = false;
      }
    },
  },
};
</script>
