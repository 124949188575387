<template>
  <div class="w-full">
    <div class="flex items-center space-x-2">
      <label v-if="title" class="textcol font-semibold flex gap-1 items-center">
        {{ title }}
        <p class="text-[10px]" v-if="required">
          <i
              class="fa-sharp fa-solid fa-star-of-life text-[7px] text-red-600"
          ></i>
        </p>
      </label>
      <div class="relative group flex justify-center mt-1" v-if="tooltip">
        <i class="fa-solid fa-circle-exclamation text-sm text-[#182451]"></i>
        <tooltip
            :title="tooltipTitle"
            :tipClass="
            tooltipClass
              ? `z-50 ${tooltipClass}`
              : 'z-50 ml-2 !bottom-6 !w-[170px]'
          "
            :bottom="true"
        />
      </div>
    </div>

    <input
        v-if="
        type !== 'date' &&
        type !== 'textarea' &&
        type !== 'select' &&
        type !== 'range' &&
        type != 'multiselect'
      "
        class="w-full border bordercol rounded py-3 outline-none px-3 text-xs"
        :class="[inputClass, { '!border-red-500 shake': error }]"
        :type="type"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        v-model="selected"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        :minlength="min"
        :maxlength="max"
        @change="(r) => type == 'file' && $emit('change', r)"
        @focus="handleFocus"
    />

    <div class="relative" v-else-if="type !== 'textarea' && type == 'select'">
      <p class="absolute left-3 top-3.5 text-xs text-gray-500" v-if="!selected">
        {{ placeholder }}
      </p>
      <select
          class="w-full border bordercol rounded py-3 outline-none px-3 text-xs"
          name=""
          id=""
          :disabled="disabled"
          v-model="selected"
          :required="required"
          :class="[inputClass, { '!border-red-500 shake': error }]"
      >
        <option v-if="loading || false" value="">
          {{ $t("globalComponents.customInput.loading") }}
        </option>

        <option
            v-for="(item, index) in selectList"
            :key="index"
            :value="item.value || item.id"
        >
          {{ item.name }}
        </option>
      </select>
    </div>

    <DatePicker
        v-else-if="type == 'date'"
        :format="customFormatter"
        :language="local"
        class="border bordercol rounded"
        input-class="w-full py-3 rounded outline-none pl-3 text-xs"
        :class="[inputClass, { 'border !border-red-500 shake': error }]"
        v-model="selected"
        :required="required"
        :placeholder="placeholder"
        :disabled-dates="oldDate ? disabledDates : {}"
    />

    <div v-else-if="type == 'range'" class="px-2 mt-1">
      <VueSlider
          :value="value || [min, max]"
          @change="(r) => $emit('change', r)"
          :min="min"
          :max="max"
          width="100%"
          :dot-size="16"
          :process-style="{
          backgroundColor:
            value[0] > min || value[1] !== max ? '#344CA0' : '#ccc',
        }"
          :rail-style="{ backgroundColor: '#ccc' }"
          :dot-style="{
          backgroundColor:
            value[0] > min || value[1] !== max ? '#344CA0' : '#ccc',
          borderColor: value[0] > min || value[1] !== max ? '#344CA0' : '#ccc',
        }"
      />
    </div>

    <multiselect
        v-else-if="type == 'multiselect'"
        :multiple="multiple"
        v-model="selected"
        :options="selectList"
        :loading="loading"
        :label="label ? label : 'name'"
        @remove="$emit('change', 0)"
        track-by="id"
        :placeholder="$t('globalComponents.customInput.selectPlaceholder')"
        selectLabel="$t('globalComponents.customInput.selectLabel')"
        selectedLabel="$t('globalComponents.customInput.selectedLabel')"
        deselectLabel="$t('globalComponents.customInput.deselectLabel')"
        openDirection="bottom"
        :hideSelected="hideSelected ? hideSelected : false"
    >
    </multiselect>

    <div v-else-if="type == 'phone'">
      <input
          class="w-full border bordercol rounded py-3 outline-none px-3 text-xs"
          type="tel"
          :disabled="disabled"
          :required="required"
          :readonly="readonly"
          v-model="selected"
          :placeholder="placeholder"
          @change="(r) => $emit('change', r)"
          @focus="handleFocus"
      />
    </div>

    <textarea
        v-else
        class="w-full border bordercol rounded py-3 outline-none px-3 text-xs"
        :class="[textareaClass, { '!border-red-500 shake': error }]"
        :type="type"
        :disabled="disabled"
        :required="required"
        :readonly="readonly"
        v-model="selected"
        :maxlength="max"
        :minlength="min"
        :placeholder="placeholder"
        :rows="rows || 1"
    ></textarea>

    <span class="text-[10px] text-red-500 block error-message">
      {{ error ? $t("globalComponents.customInput.errorMessage") : "" }}
    </span>
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import {tr} from "vuejs-datepicker/dist/locale";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Multiselect from "vue-multiselect";
import tooltip from "@/components/tooltip.vue";
import moment from "moment";

export default {
  name: "multipleInputs",
  components: {
    DatePicker,
    VueSlider,
    Multiselect,
    tooltip,
  },
  props: [
    "title",
    "type",
    "value",
    "required",
    "disabled",
    "min",
    "max",
    "readonly",
    "textareaClass",
    "selectList",
    "placeholder",
    "inputClass",
    "rows",
    "oldDate",
    "loading",
    "multiple",
    "label",
    "hideSelected",
    "isError",
    "tooltip",
    "tooltipTitle",
    "tooltipClass",
  ],
  data() {
    return {
      selected: this.value,
      load: false,
      local: tr,
      error: null,
      valueChanged: false,
    };
  },
  computed: {
    disabledDates() {
      return {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
      };
    },
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("LL");
    },
    handleFocus() {
      if (this.selected === "0") this.selected = "";
    },
    validateInput() {
      // Reset error state
      this.error = false;

      // Only validate if isError prop is true
      if (!this.isError) {
        return true;
      }

      // Required field validation
      if (this.required) {
        if (!this.selected && this.selected !== 0) {
          this.error = true;
          return false;
        }

        if (Array.isArray(this.selected) && this.selected.length === 0) {
          this.error = true;
          return false;
        }

        if (typeof this.selected === "string" && this.selected.trim() === "") {
          this.error = true;
          return false;
        }
      }

      // Type-specific validations
      switch (this.type) {
        case "email":
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (this.selected && !emailRegex.test(this.selected)) {
            this.error = true;
            return false;
          }
          break;

        case "number":
          const numValue = Number(this.selected);
          if (isNaN(numValue)) {
            this.error = true;
            return false;
          }
          if (this.min !== undefined && numValue < this.min) {
            this.error = true;
            return false;
          }
          if (this.max !== undefined && numValue > this.max) {
            this.error = true;
            return false;
          }
          break;

        case "tel":
        case "phone":
          const phoneRegex = /^\+?[\d\s-]{8,}$/;
          if (this.selected && !phoneRegex.test(this.selected)) {
            this.error = true;
            return false;
          }
          break;

        case "url":
          const urlRegex =
              /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
          if (this.selected && !urlRegex.test(this.selected)) {
            this.error = true;
            return false;
          }
          break;

        case "text":
        case "textarea":
          if (this.min && this.selected?.length < this.min) {
            this.error = true;
            return false;
          }
          if (this.max && this.selected?.length > this.max) {
            this.error = true;
            return false;
          }
          break;

        case "date":
          if (this.selected) {
            const selectedDate = new Date(this.selected);
            if (isNaN(selectedDate.getTime())) {
              this.error = true;
              return false;
            }
            if (this.oldDate && selectedDate < new Date()) {
              this.error = true;
              return false;
            }
          }
          break;

        case "select":
        case "multiselect":
          if (
              this.required &&
              (!this.selected ||
                  (Array.isArray(this.selected) && this.selected.length === 0))
          ) {
            this.error = true;
            return false;
          }
          break;
      }

      return !this.error;
    },
    setError(message) {
      this.error = message || true;
    },
    clearError() {
      this.error = false;
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    selected(val) {
      if (this.type === "number") {
        if (val === "" || val === null) {
          this.$emit("change", "");
          return;
        }

        let numVal = Number(val);

        if (isNaN(numVal)) {
          return;
        }

        if (this.max !== undefined && numVal > this.max) {
          numVal = this.max;
        }
        if (this.min !== undefined && numVal < this.min && val !== "") {
          numVal = this.min;
        }

        if (numVal !== val) {
          this.selected = numVal;
        }

        this.$emit("change", numVal);
      } else {
        this.$emit("change", val);
      }

      if (this.valueChanged) {
        this.validateInput();
      }

      if (this.selected) {
        this.error = false;
      }

      this.valueChanged = true;
    }
  },
  model: {
    event: "change",
    prop: "value",
  },
  created() {
    if (this.type != "file") {
      this.selected = this.value;
    } else if (this.type == "file" && this.videoId) this.getUrl();
  },
};
</script>

<style>
.shake {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-4px);
  }

  40% {
    transform: translateX(4px);
  }

  60% {
    transform: translateX(-4px);
  }

  80% {
    transform: translateX(4px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
