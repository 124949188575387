const baseUrl = window.ENV?.VUE_APP_BASE_URL || "http://localhost";
const supportUrl = window.ENV?.VUE_APP_BASE_URL || "https://management.softwareoptimize.com";

export const auth = {
  signIn: baseUrl + "/users/sign/in",
  signUp: baseUrl + "/users/sign/up",
  signControl: baseUrl + "/users/sign/control",
  setPassword: baseUrl + "/users/set/password",
  signOut: baseUrl + "/users/sign/out",
  resetPassword: baseUrl + "/users/reset/password",
  confirmPassword: baseUrl + "/users/confirm/password",
  verifyResetToken: baseUrl + "/users/verify/reset/password",
  setLanguage: baseUrl + "/users/set/language",
};
export const users = {
  getAll: baseUrl + "/users/get/all",
  add: baseUrl + "/users/add",
  edit: baseUrl + "/users/edit",
  delete: baseUrl + "/users/delete",
  isExtractControl: baseUrl + "/users/extract/control",
  verifyExtract: baseUrl + "/users/verify/extract",
  tourControl: baseUrl + "/users/tour/module",
  getTour: baseUrl + "/get/tour/module",
};
export const cv = {
  getAll: baseUrl + "/cv/get/all",
  getDetail: baseUrl + "/cv/get/detail",
  getInformation: baseUrl + "/cv/get/information",
  oldResume: baseUrl + "/cv/get/old/resume",
  getFilterAll: baseUrl + "/cv/get/filter/all",
  allHistory: baseUrl + "/cv/all/history",

  add: baseUrl + "/cv/add",
  edit: baseUrl + "/cv/edit",
  editInformation: baseUrl + "/cv/edit/information",
  deleteInformation: baseUrl + "/cv/delete/information",
  changeStatus: baseUrl + "/cv/change/status",
  allChangeStatus: baseUrl + "/cv/all/change/status",
  isFavorite: baseUrl + "/cv/is/favorite",
  extractor: baseUrl + "/cv/extractor",
  addExtractor: baseUrl + "/cv/add/extract",
  translator: baseUrl + "/cv/translator",

  getCvTrain: baseUrl + "/cv/get/train",
  getConflictCv: baseUrl + "/cv/get/conflict/resume",
  chanceConflicts: baseUrl + "/cv/chance/conflict/resume",
  deleteTrain: baseUrl + "/cv/delete/train",
};
export const educationLife = {
  getAll: baseUrl + "/cv/education/life/get/all",
  add: baseUrl + "/cv/education/life/add",
  edit: baseUrl + "/cv/education/life/edit",
  delete: baseUrl + "/cv/education/life/delete",
};
export const language = {
  getAll: baseUrl + "/cv/language/get/all",
  add: baseUrl + "/cv/language/add",
  create: baseUrl + "/cv/language/create",
  edit: baseUrl + "/cv/language/edit",
  delete: baseUrl + "/cv/language/delete",
};
export const skills = {
  getAll: baseUrl + "/cv/skills/get/all",
  add: baseUrl + "/cv/skills/add",
  edit: baseUrl + "/cv/skills/edit",
  delete: baseUrl + "/cv/skills/delete",
};
export const positions = {
  add: baseUrl + "/cv/position/add",
};
export const cvUtility = {
  add: baseUrl + "/cv/city/add",
};
export const workExperience = {
  getAll: baseUrl + "/cv/work/experience/get/all",
  add: baseUrl + "/cv/work/experience/add",
  edit: baseUrl + "/cv/work/experience/edit",
  delete: baseUrl + "/cv/work/experience/delete",
};
export const contact = {
  getAll: baseUrl + "/cv/contact/get/all",
  add: baseUrl + "/cv/contact/add",
  edit: baseUrl + "/cv/contact/edit",
  delete: baseUrl + "/cv/contact/delete",
};
export const seminarAndCourse = {
  getAll: baseUrl + "/cv/seminar/course/get/all",
  add: baseUrl + "/cv/seminar/course/add",
  edit: baseUrl + "/cv/seminar/course/edit",
  delete: baseUrl + "/cv/seminar/course/delete",
};
export const computerSkills = {
  getAll: baseUrl + "/cv/computer/skills/get/all",
  add: baseUrl + "/cv/computer/skills/add",
  edit: baseUrl + "/cv/computer/skills/edit",
  delete: baseUrl + "/cv/computer/skills/delete",
};
export const personalInformation = {
  getAll: baseUrl + "/cv/personal/information/get/all",
  add: baseUrl + "/cv/personal/information/add",
};
export const projectsPublications = {
  getAll: baseUrl + "/cv/projects/publications/get/all",
  add: baseUrl + "/cv/projects/publications/add",
  edit: baseUrl + "/cv/projects/publications/edit",
  delete: baseUrl + "/cv/projects/publications/delete",
};
export const support = {
  getAll: supportUrl + "/support/get/all",
  getCategoryAll: supportUrl + "/support/get/category/all",
  getDetail: supportUrl + "/support/get/detail",
  sendMessage: supportUrl + "/support/send/message",
  add: supportUrl + "/support/add",
  close: supportUrl + "/support/close",
};

// ------------ projects section --------------
export const project = {
  getAll: baseUrl + "/project/get/all",
  getFilter: baseUrl + "/project/get/filter",
  changeStatus: baseUrl + "/project/change/status",
  getDetail: baseUrl + "/project/get/detail",
  createJobDescription: baseUrl + "/project/create/job/description",
  createAd: baseUrl + "/project/create/ad",
  editAd: baseUrl + "/project/edit/ad",
  addAd: baseUrl + "/project/add/ad",
  getList: baseUrl + "/project/get/list",
  add: baseUrl + "/project/add",
  edit: baseUrl + "/project/edit",
  delete: baseUrl + "/project/delete",
  update: baseUrl + "/project/edit/ad",
  advert: {
    getDetail: baseUrl + "/project/advert/get/detail",
    save: baseUrl + "/project/advert/save",
    generate: baseUrl + "/project/advert/generate",
  },
  getCompanyUsers: baseUrl + "/project/get/advisors",
};

export const projectConnect = {
  add: baseUrl + "/project/connect/add",
  addResume: baseUrl + "/project/connect/add/resume",
  delete: baseUrl + "/project/connect/delete",
  getUserAll: baseUrl + "/project/connect/get/user/all",
  getResumeRecommendations:
    baseUrl + "/project/connect/get/resume/recommendations",
  setInadequate: baseUrl + "/project/connect/set/inadequate",
};

export const shortList = {
  getAll: baseUrl + "/project/short/get/all",
  add: baseUrl + "/project/short/add",
  delete: baseUrl + "/project/short/delete",

  setInterview: baseUrl + "/project/short/set/interview",
  setIK: baseUrl + "/project/short/set/ik",
  setTechnical: baseUrl + "/project/short/set/technical",
  setAssessment: baseUrl + "/project/short/set/assessment",

  workHired: baseUrl + "/project/short/work/hired",
};

export const technicalInterviewGeneral = {
  selectedDate:
    baseUrl + "/project/technical/interview/selected/date/send/mail",
  getInterviews: baseUrl + "/project/technical/interview/get/all",
  sendInterview: baseUrl + "/project/technical/interview/send",
  getAttendeeMails:baseUrl + "/project/technical/interview/get/mails",
  interviewSendAgain: baseUrl + "/project/technical/interview/send-again",
  interviewEmailSendAgain: baseUrl + "/project/technical/interview/email-send-again",
};

export const technicalInterviewMicrosoft = {
  getAuthUrl: baseUrl + "/project/technical/interview/microsoft/get/auth/url",
  authorize: baseUrl + "/project/technical/interview/microsoft/authorize",
  refreshAuth:
    baseUrl + "/project/technical/interview/microsoft/refresh/microsoft",
  getCalendarAll:
    baseUrl + "/project/technical/interview/microsoft/get/calendar/all",
  selectedDate:
    baseUrl + "/project/technical/interview/selected/date/send/mail",
  meetGenerate:
    baseUrl + "/project/technical/interview/microsoft/meet-generate",
  tokenController:
    baseUrl + "/project/technical/interview/microsoft/token/controller",
};

export const technicalInterviewGoogle = {
  authorize: baseUrl + "/project/technical/interview/google/get/auth/url",
  getCalendarAll:
    baseUrl + "/project/technical/interview/google/get/calendar/all",
  meetCreateToken: baseUrl + "/project/technical/interview/google/meet/auth",
  meetGenerate: baseUrl + "/project/technical/interview/google/meet-generate",
  getMeetDetails:
    baseUrl + "/project/technical/interview/get/meet-detail",
  tokenController:
    baseUrl + "/project/technical/interview/google/token/controller",
};

export const projectInterview = {
  getAll: baseUrl + "/project/interview/get/all",
  getCvQuestion: baseUrl + "/project/interview/get/cv/quesiton/all",
  getFilter: baseUrl + "/project/interview/get/filter",
  getQuestionAnswer: baseUrl + "/project/interview/get/question/answer",
  getCandidateQuesion: baseUrl + "/project/interview/get/candidate/question",
  generateQuestion: baseUrl + "/project/interview/generate/question",
  addComment: baseUrl + "/project/interview/add/comment",
  evaluationCriteria: baseUrl + "/project/interview/add/evaluation/criteria",
  add: baseUrl + "/project/interview/add",
  addBulkQuestion: baseUrl + "/project/interview/add/bulk/question",
  edit: baseUrl + "/project/interview/edit",
  delete: baseUrl + "/project/interview/delete",
  changeOrder: baseUrl + "/project/interview/change/order",
  mail: baseUrl + "/project/interview/mail",
  createQuesion: baseUrl + "/project/interview/create/question",
  deleteAction: baseUrl + "/project/interview/delete/action",
  chanceQuestionWeight: baseUrl + "/project/interview/chance/question/weight",
};

//-------- settings sections -----------
export const settingGeneral = {
  getAll: baseUrl + "/setting/general/get/all",
  edit: baseUrl + "/setting/general/edit",
  getFormatFile: baseUrl + "/setting/general/get/format/file",
  interviewTypeEdit: baseUrl + "/setting/general/edit/interview-type",
  getInterviewType: baseUrl + "/setting/general/get/interview-type/all",
};

export const settingInterview = {
  getAll: baseUrl + "/setting/interview/get/all",
  getDataAll: baseUrl + "/setting/interview/get/data/all",
  getProject: baseUrl + "/setting/interview/get/project",
  changeOrder: baseUrl + "/setting/interview/change/order",
  add: baseUrl + "/setting/interview/add",
  edit: baseUrl + "/setting/interview/edit",
  delete: baseUrl + "/setting/interview/delete",
};

export const settingContact = {
  getAll: baseUrl + "/setting/contact/get/all",
  add: baseUrl + "/setting/contact/add",
  edit: baseUrl + "/setting/contact/edit",
};

export const settingContactPersonal = {
  getAll: baseUrl + "/setting/contact/persons/get/all",
  edit: baseUrl + "/setting/contact/persons/edit",
  add: baseUrl + "/setting/contact/persons/add",
  delete: baseUrl + "/setting/contact/persons/delete",
};

export const settingConfigration = {
  getMail: baseUrl + "/setting/configration/get/mail",
  edit: baseUrl + "/setting/configration/edit/mail",
};

//-------- utility sections -----------
export const utility = {
  getSectorAll: baseUrl + "/utility/get/sector/all",
  getCityAll: baseUrl + "/utility/get/city/all",
  getInstitutionAll: baseUrl + "/utility/get/institution/all",
  getCorporationAll: baseUrl + "/utility/get/corporation/all",
  getCountryAll: baseUrl + "/utility/get/country/all",
  getDepartmentAll: baseUrl + "/utility/get/department/all",
  getLanguagesAll: baseUrl + "/utility/get/languages/all",
  getSkillAll: baseUrl + "/utility/get/skill/all",
  getLogModule: baseUrl + "/utility/get/log/module",
  readFile: baseUrl + "/utility/read/file",
  getNotificationLimit: baseUrl + "/utility/get/notification/limit",
};
export const utilityPosition = {
  getAll: baseUrl + "/utility/position/get/all",
  add: baseUrl + "/utility/position/add",
};

//-------- general sections -----------
export const general = {
  getTotalCount: baseUrl + "/general/get/total/count",
  getpositionAll: baseUrl + "/general/get/project",
  getresumeAll: baseUrl + "/general/get/cv",
};
export const company = {
  getPaginationAll: baseUrl + "/company/get/pagination/all",
  getAll: baseUrl + "/company/get/all",
  add: baseUrl + "/company/add",
  edit: baseUrl + "/company/edit",
  delete: baseUrl + "/company/delete",
};
export const personelInformation = {
  mail: baseUrl + "/personel/information/mail",
  getAll: baseUrl + "/personel/information/get/all",
};
