<template>
  <div>
    <form @submit.prevent="save">
      <navbar 
        :load="getLoad"
        :buttonLoad="load"
        :title="$t('settings.configration.index.title')"
        :description="$t('settings.configration.index.text')"
      />
  
      <div
        v-if="!getLoad"
        class="mt-3 mb-5 w-full max-md:mt-0 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"
      >
        <div class="flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.configration.index.emailTitle") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.configration.index.emailText") }}
            </p>
          </div>
          <div class="w-6/12 h-full grid grid-cols-1 gap-4 max-md:w-full">
            <Inputs
              :title="$t('settings.configration.index.sunucuAdress')"
              v-model="form.mailServer"
              :required="false"
              type="text"
              min="2"
              max="100"
            />
            <Inputs
              :title="$t('settings.configration.index.username')"
              v-model="form.mailName"
              :required="false"
              type="text"
              min="2"
              max="100"
            />
            <Inputs
              :title="$t('settings.configration.index.password')"
              v-model="form.mailPassword"
              :required="false"
              type="text"
              min="2"
              max="100"
            />

            <Inputs
              :title="$t('settings.configration.index.port')"
              v-model="form.mailPort"
              :required="false"
              type="select"
              :selectList="[
                {
                  value: '587',
                  name: '587',
                },
                {
                  value: '465',
                  name: '465',
                },
                {
                  value: '25',
                  name: '25',
                },
              ]"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import navbar from "../components/navbar.vue";
import Inputs from "@/components/customInput.vue";

import { settingConfigration } from "@/networking/urlmanager";

export default {
  name: "konfigrasyon-page",
  components: {
    navbar,
    Inputs,
  },
  data() {
    return {
      form: {
        mailServer: "",
        mailName: "",
        mailPassword: "",
        mailPort: "",
      },
      load: false,
      getLoad: false,
      initialValues: [],
    };
  },
  methods: {
    getAll() {
      this.getLoad = true;
      this.axios
        .get(settingConfigration.getMail, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
          },
        })
        .then((res) => {
          if (res.data.data.length > 0) {
            const { mailServer, mailName, mailPassword, mailPort } =
              res.data.data[0];
            this.form.mailServer = mailServer;
            this.form.mailName = mailName;
            this.form.mailPassword = mailPassword;
            this.form.mailPort = mailPort;
          }
          this.getLoad = false;
        })
        .catch((err) => {
          this.getLoad = false;
          console.log(err);
          this.authController(err);
        })
        .finally(() => {
          this.initialValues = _.cloneDeep(this.form);
        });
    },
    save() {
      this.load = true;
      this.axios
        .post(
          settingConfigration.edit,
          {
            mailServer: this.form.mailServer,
            mailName: this.form.mailName,
            mailPassword: this.form.mailPassword,
            mailPort: this.form.mailPort,
          },
          {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          }
        )
        .then((res) => {
          this.successMessage(res.data.message);
          this.getAll();
          this.load = false;
        })
        .catch((err) => {
          this.warningMesage(
            this.$t("settings.configration.index.warningMesage")
          );
          this.load = false;
        });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.getLoad && !_.isEqual(this.form, this.initialValues)) {
      this.verifiedMessage(
        this.$t("settings.general.index.leaveMessage"),
        this.$t("settings.general.index.leaveMessageYes"),
        this.$t("settings.general.index.leaveMessageNo")
      ).then((result) => {
        if (result.isConfirmed) {
          next(true);
        } else {
          next(false);
        }
      });
    } else {
      next(true);
    }
  },
  created() {
    this.getAll();
  },
};
</script>
