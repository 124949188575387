<template>
  <modal
    name="add-language"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.information.languages.add.add") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <multiSelectVue
          :taggable="false"
          :placeholder="
            $t('cv.profile.information.languages.languageSelect.choose')
          "
          :label="
            $t('cv.profile.information.languages.languageSelect.language')
          "
          ref="languageRef"
          v-model="language"
          :required="true"
          :getOptions="getLang"
        />

        <customInput
          v-model="degree"
          type="select"
          :selectList="languageDegree"
          :placeholder="
            $t('cv.profile.information.languages.add.levelPlacholder')
          "
          :title="$t('cv.profile.information.languages.add.level')"
        />

        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.information.languages.add.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { language, utility } from "@/networking/urlmanager.js";

export default {
  name: "edit-language",
  props: ["isEdit"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      language: null,
      degree: 0,
      languageDegree: [
        {
          value: 0,
          name: this.$t("cv.profile.information.languages.index.unspecified"),
        },
        {
          value: 1,
          name: this.$t("cv.profile.information.languages.index.beginner"),
        },
        {
          value: 2,
          name: this.$t(
            "cv.profile.information.languages.index.intermediateLevel"
          ),
        },
        {
          value: 3,
          name: this.$t("cv.profile.information.languages.index.advancedLevel"),
        },
        {
          value: 4,
          name: this.$t("cv.profile.information.languages.index.topLevel"),
        },
      ],
    };
  },
  methods: {
    show() {
      this.$modal.show("add-language");
    },
    hide() {
      this.$modal.hide("add-language");
    },
    save() {
      this.load = true;

      this.$refs.languageRef.validate();

      if (this.language) {
        this.$store.commit("addLanguage", {
          name: this.language,
          level: this.degree,
        });

        this.language = "";
        this.degree = 0;
        this.successMessage();

        this.load = false;
        this.hide();
      } else {
        this.load = false;
      }
    },
    async getLang() {
      const response = await this.axios.get(utility.getLanguagesAll);
      return response.data.data;
    },
    add() {
      this.load = true;
      this.$refs.languageRef.validate();
      if (this.language) {
        this.axios
          .post(language.create, {
            cvId: this.$route.params.id,
            languageId: this.language.id,
            level: this.degree,
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$emit("refresh", true);
            this.load = false;
            this.hide();
          })
          .catch((err) => {
            this.authController(err);
            this.load = false;
          });
      } else {
        this.load = false;
      }
    },
  },
};
</script>
