<template>
  <div class="mt-2 overflow-y-auto" style="height: calc(100% - 110px)">
    <div v-if="!load && List.length > 0">
      <div v-for="(item, index) in List" :key="index" class="w-full">
        <accordion :header="formatText(item.fullname)" :index="index" :openedItem="openedItem" @toggle="toggle"
          headerClass="!text-sm" class="w-full">
          <template v-slot>
            <div>
              <div v-for="(r, i) in item.newData" :key="i" class="flex w-full justify-between mb-3 items-center">
                <router-link :to="'/cv/profile/' + r.id" target="_blank" class="p-1 text-[14px] w-9/12 text-left block">
                  <span
                    :class="`${r.isDuplicate != 1 ? 'bg-red-100' : 'bg-blue-100'} ${r.isDuplicate != 1 ? 'text-red-500' : 'text-blue-500'} px-1.5 py-0.5 rounded-md text-xs mr-3`">
                    {{ r.isDuplicate != 1 ? "Eski" : "Yeni" }}
                  </span>
                  {{ formatText(r.fullname) }}
                </router-link>
                <button @click="chanceConflicts(item.newData, r)"
                  class="w-3/12 p-1 border text-sm bordercol rounded hover:bgcol hover:text-white duration-500">
                  {{ $t("cv.components.cvListModal.accept") }}
                </button>
              </div>
            </div>
          </template>
        </accordion>
      </div>
    </div>
  </div>
</template>
<script>
import { cv } from "@/networking/urlmanager";
import accordion from "@/components/accordion.vue";

export default {
  name: "conflicts-cv",
  props: ["load"],
  components: { accordion },
  data() {
    return {
      openedItem: null,
      List: [],
    };
  },
  methods: {
    toggle(index) {
      this.openedItem = this.openedItem === index ? null : index;
    },
    async getAll(loading = true) {
      try {
        if (this.load) return;

        this.$emit("load", loading);
        const res = await this.axios.get(cv.getConflictCv);
        this.List = res.data.data;

        this.$emit("chanceList", this.List);
        this.$emit("load", false);
      } catch (err) {
        console.log(err);
      }
    },
    async chanceConflicts(list, selected) {
      try {
        this.$emit("load", true);
        await this.axios.post(cv.chanceConflicts, { list, list, selected: selected });
        this.$emit("load", false);
        this.$emit("refresh", true)
        this.getAll(true);
      } catch (err) {
        this.$emit("load", false);
        console.log(err);
      }
    },
  },
};
</script>