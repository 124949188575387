<template>
  <div class="interview-method">
    <div v-if="isOnline">
      <meet :detail="selectedAddress" v-if="isOnline" ref="meetRef" />
    </div>
    <div v-else class="min-h-[300px]">
      <tableLoader v-if="isLoadingAddress" class="min-h-[300px]" :load="isLoadingAddress" colspan="7" />
      <div v-else class="space-y-4">
        <div v-if="addressDetails.adress" class="contact-detail">
          <strong>
            {{
      $t(
        "project.projectUser.components.shortList.components.steps.interviewMethod.address"
      )
    }}
          </strong>
          <p>{{ addressDetails.adress }}</p>
        </div>

        <div v-if="addressDetails.phone" class="contact-detail">
          <strong>{{
      $t(
        "project.projectUser.components.shortList.components.steps.interviewMethod.phone"
      )
    }}</strong>
          <p>{{ addressDetails.phone }}</p>
        </div>

        <div v-if="addressDetails.email" class="contact-detail">
          <strong>
            {{
      $t(
        "project.projectUser.components.shortList.components.steps.interviewMethod.email"
      )
    }}
          </strong>
          <p>{{ addressDetails.email }}</p>
        </div>

        <div v-if="addressDetails.website" class="contact-detail">
          <strong>{{
      $t(
        "project.projectUser.components.shortList.components.steps.interviewMethod.webSite"
      )
    }}</strong>
          <p>{{ addressDetails.website }}</p>
        </div>
        <async-button :load="isGeneratingAddressMeeting" @click="generateAddressMeetingInvitation" :text="isGeneratingAddressMeeting
      ? $t(
        'project.projectUser.components.shortList.components.steps.interviewMethod.sendAddressing'
      )
      : $t(
          'project.projectUser.components.shortList.components.steps.interviewMethod.sendAddress'
        )
      " buttonClass="w-full" />
      </div>
    </div>
  </div>
</template>

<script>
import meet from "../meet/index.vue";
import AsyncButton from "@/components/button.vue";
import tableLoader from "@/components/table-loader.vue";
import {
  technicalInterviewGoogle,
  technicalInterviewMicrosoft,
  settingContact
} from "@/networking/urlmanager";
import moment from 'moment';

export default {
  name: "AddressInterviewMethod",
  components: {
    meet,
    AsyncButton,
    tableLoader
  },
  data() {
    return {
      isLoadingAddress: false,
      selectedAddress: {},
      addressDetails: {},
      isGeneratingAddressMeeting: false,
      defaultService: this.$store.state.integration.defaultService,
      isOnline: false,
      isResend: false,
    };
  },
  methods: {
    getAddressServiceCredentials(service) {
      const vuexState = JSON.parse(localStorage.getItem("vuex"));

      if (service === 'microsoft') {
        const microsoftToken = vuexState.integration?.microsoft?.token;
        const microsoftRefreshToken = vuexState.integration?.microsoft?.refreshToken;
        const microsoftDate = vuexState.integration?.microsoft?.date;

        return {
          token: microsoftToken,
          refreshToken: microsoftRefreshToken,
          isCredentialsValid: microsoftToken &&
            microsoftDate &&
            moment(microsoftDate).diff(moment(), "minutes") > 0
        };
      }

      if (service === 'google') {
        const googleToken = vuexState.integration.google.token;
        const googleRefreshToken = vuexState.integration.google.refreshToken;
        const googleDate = vuexState.integration.google.date;

        return {
          token: googleToken,
          refreshToken: googleRefreshToken,
          isCredentialsValid: googleToken &&
            googleDate &&
            moment(googleDate).diff(moment(), "minutes") > 0
        };
      }

      return { isCredentialsValid: false };
    },

    extractValidEmail(emailString) {
      if (!emailString) {
        console.error('No email provided');
        return null;
      }

      // Use a more robust email validation regex
      const emailRegex = /[\w.-]+@[\w.-]+\.\w+/;
      const match = emailString.match(emailRegex);

      if (match) {
        return match[0];
      }

      console.error('Invalid email format:', emailString);
      return null;
    },

    async generateAddressMeetingInvitation() {
      try {
        this.isGeneratingAddressMeeting = true;
        const vuexState = JSON.parse(localStorage.getItem("vuex"));


        if (this.defaultService == 1) {
          const microsoftAuth = this.getAddressServiceCredentials('microsoft');

          if (microsoftAuth.isCredentialsValid) {
            const applicantEmail =
              this.selectedAddress.email ||
              this.selectedAddress.contactEmail ||
              this.selectedAddress.applicantEmail;

            if (!applicantEmail) {
              throw new Error('No applicant email found');
            }

            const response = await this.axios.post(
              technicalInterviewMicrosoft.meetGenerate,
              {
                access_token: microsoftAuth.token,
                refresh_token: microsoftAuth.refreshToken,
                meetCount: this.selectedAddress.meetCount,
                cvId: this.selectedAddress.cvId,
                projectId: this.selectedAddress.projectId,
                applicantMail: applicantEmail,
                projectName: this.$route.query.projectName,
                interviewId: this.$store.state.interviewId,
              }
            );

            this.$emit("refresh", true);
          }
        } else if (this.defaultService == 2) {
          const googleAuth = this.getAddressServiceCredentials('google');

          if (googleAuth.isCredentialsValid) {
            // Fallback to different email properties
            const contactEmail =
              this.selectedAddress.contactEmail ||
              this.selectedAddress.email ||
              this.selectedAddress.applicantEmail;

            const email = this.extractValidEmail(contactEmail);

            if (!email) {
              throw new Error('No valid email found');
            }

            const response = await this.axios.post(
              technicalInterviewGoogle.meetGenerate,
              {
                access_token: googleAuth.token,
                refresh_token: this.$store.state.integration?.google.token,
                meetCount: this.selectedAddress.meetCount,
                cvId: this.selectedAddress.cvId,
                projectId: this.selectedAddress.projectId,
                applicantMail: email,
                projectName: this.$route.query.projectName,
                interviewId: this.$store.state.interviewId,
              }
            );

            this.$emit("refresh", true);
          }
        } else {
          console.error("Invalid address service selection", this.defaultService);
        }
      } catch (err) {
        this.handleAddressServiceError(err);
        console.error(err);
      } finally {
        this.isGeneratingAddressMeeting = false;
      }
    },
    async fetchAddressDetails() {
      try {
        this.isLoadingAddress = true;
        const res = await this.axios.get(settingContact.getAll, {
          headers: {
            Authorization: `Bearer ${this.$store.state.userData.token}`,
          },
        });
        this.addressDetails = res.data?.data[0] || {};
      } catch (err) {
        this.handleAddressServiceError(err);
        console.error(err);
      } finally {
        this.isLoadingAddress = false;
      }
    },

    handleAddressServiceError(err) {
      console.error("Address service error:", err);
    },
  },
  mounted() {
    if (this.isOnline === 2) {
      this.fetchAddressDetails();
    }
  },
  watch: {
    isOnline: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.meetRef.isResend = this.isResend;
          });
        }
      },
    },
  },
};
</script>

<style scoped>
.interview-method {
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.contact-detail {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-detail strong {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.contact-detail p {
  margin: 0;
  color: #666;
}
</style>