import moment from "moment";
import "moment/locale/tr";
import { auth } from "@/networking/urlmanager";
import i18n from "./i18n";
import { technicalInterviewGoogle } from "@/networking/urlmanager";

export default {
  computed: {
    getNowDate() {
      return moment().format("YYYY-MM-DD");
    },
    getFormatDate(val) {
      if (val) return moment(val).format("LL");
      else return i18n.t("noDate");
    },
    getDateFormat(val) {
      if (val) return moment(val).format("LL");
      else return i18n.t("noDate");
    },

    isMobile() {
      // let check = window.innerWidth<700;
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);

      return check;
    },
  },
  methods: {
    notificationAlert(message, type = "warning", position, timeout) {
      const errorMessage = (message.response ? message.response.data.details?.body[0].message ? message.response.data.details.body[0].message : message.response.data.message : message.message) || message;

      // warning, success, info, error 

      (type ? this.$toast[type] : this.$toast)(errorMessage, {
        position: position || "bottom-right",
        timeout: timeout || 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },

    authController(error) {
      const errorMessage = error.response
        ? error.response.data.details?.body[0].message
          ? error.response.data.details.body[0].message
          : error.response.data.message
        : error.message;

      if (errorMessage) {
        this.$swal({
          title: i18n.t("commonMixins.sweetAlerts.warningAlert.title"),
          text: errorMessage,
          icon: "warning",
          confirmButtonText: i18n.t(
            "commonMixins.sweetAlerts.warningAlert.confirmButtonText"
          ),
        });
      } else {
        this.$swal({
          title: i18n.t("commonMixins.sweetAlerts.warningAlert.title"),
          text: error,
          icon: "warning",
          confirmButtonText: i18n.t(
            "commonMixins.sweetAlerts.warningAlert.confirmButtonText"
          ),
        });
      }
    },

    successMessage(message) {
      this.$swal({
        position: "center",
        icon: "success",
        title: i18n.t("commonMixins.sweetAlerts.successAlert.title"),
        text: message ? message : this.$t("message.success"),
        showConfirmButton: true,
        confirmButtonText: i18n.t(
          "commonMixins.sweetAlerts.successAlert.confirmButtonText"
        ),
        timer: 1000,
      });
    },

    warningMesage(message, timer) {
      return this.$swal({
        position: "center",
        icon: "warning",
        title: i18n.t("commonMixins.sweetAlerts.warningAlert.title"),
        text: message,
        showConfirmButton: true,
        confirmButtonText: i18n.t(
          "commonMixins.sweetAlerts.warningAlert.confirmButtonText"
        ),
        timer: timer ? timer : 1000,
      });
    },

    verifiedMessage(message, yesButon, noButon) {
      const verified = this.$swal({
        title: i18n.t("commonMixins.sweetAlerts.verifiedAlert.title"),
        text: message ? message : this.$t("message.delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: yesButon
          ? yesButon
          : i18n.t("commonMixins.sweetAlerts.verifiedAlert.confirmButtonText"),
        cancelButtonText: noButon
          ? noButon
          : i18n.t("commonMixins.sweetAlerts.verifiedAlert.cancelButtonText"),
      });
      return verified;
    },

    formatText(text) {
      try {
        return text
          .trim()
          .split(" ")
          .map((word) => {
            const wordLower = word.toLowerCase();
            const conjunctions = [
              "ve",
              "ya",
              "veya",
              "ancak",
              "ama",
              "fakat",
              "gibi",
              "ise",
              "ki",
              "ne",
              "veya",
              "veyahut",
              "de",
              "da",
              "ile",
              "ilelebet",
              "hani",
              "mademki",
              "eğer",
              "lakin",
              "ama",
              "meğer",
              "meğerki",
              "zira",
              "yahut",
              "yoksa",
              "oysa",
              "sanki",
              "hem",
              "ise",
              "de",
              "ya",
              "da",
              "ki",
              "ve/va",
              "ile",
              "ilelebet",
              "çünkü",
              "se",
              "çünkü ki",
              "rağmen",
              "hâlbuki",
              "lâkin",
              "ancak",
              "fakat",
              "oysa",
              "ise",
              "yahut",
              "veya",
              "kâh",
              "kî",
              "kuddise sirruh",
              "âhâ",
              "elbette",
              "elbetteki",
              "gerçi",
              "hâlbuki",
              "hattâ",
              "hele",
              "hem",
              "her ne kadar",
              "ki",
              "lâkin",
              "madem",
              "mademki",
              "meğer",
              "meğer ki",
              "ne",
              "neden",
              "oysa",
              "sanki",
              "ve",
              "velev ki",
              "veya",
              "veyahut",
              "yahut",
              "yoksa",
              "zira",
              // English conjunctions
              "and",
              "or",
              "but",
              "then",
              "because",
            ];

            const exceptions = ["/", "§", "€", "$", "£", "₺", "₿", "₽", "₹"];

            if (
              exceptions.includes(wordLower) ||
              /\b(ui|ux)\b/i.test(wordLower)
            ) {
              return word.replace(/\b(ui|ux)\b/gi, (match) =>
                match.toUpperCase()
              );
            } else if (conjunctions.includes(wordLower)) {
              return word;
            } else {
              return wordLower.charAt(0).toUpperCase() + wordLower.slice(1);
            }
          })
          .join(" ");
      } catch (err) {
        return text;
      }
    },

    checkOutlookCredentialsMixin() {
      const vuexState = JSON.parse(localStorage.getItem("vuex"));
      const microsoftToken = vuexState.integration?.microsoft?.token;
      const microsoftDate = vuexState.integration?.microsoft?.date;

      return {
        microsoftToken,
        microsoftDate,
        credentialsExist: microsoftToken && microsoftDate && moment(microsoftDate).diff(moment(), "minutes") > 0,
      };
    },

    checkGoogleCredentialsMixin() {
      const vuexState = JSON.parse(localStorage.getItem("vuex"));
      const googleToken = vuexState.integration?.google?.token;
      const googleDate = vuexState.integration?.google?.date;
      const refresh_token = vuexState.integration?.google?.refreshToken;
      return {
        googleToken,
        googleDate,
        refresh_token,
        credentialsExist: (googleToken && googleDate && moment(googleDate).diff(moment(), "minutes") > 0 ),
      };
    },

    async authorizeMixin(callback) {
      const defaultService = this.$store.state.integration.defaultService
      const checker = defaultService == 1 ? this.checkOutlookCredentialsMixin() : this.checkGoogleCredentialsMixin();
      const { credentialsExist } = checker

      if (!credentialsExist && this.defaultService == 1) {
        this.warningMesage(this.$t("project.projectUser.components.shortList.components.selectDateModal.warningMessageOutlook"), 3500)
          .then(() => {
            let CLIENT_ID = "fd7fc1b1-b513-40d9-96d7-50fb275b948e"
            let DOMAIN = new URL(window.location.href).origin;
            let SCOPE = "User.ReadWrite.All Calendars.Read Calendars.ReadWrite profile openid offline_access OnlineMeetings.ReadWrite"

            window.open(`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${CLIENT_ID}&redirect_uri=${DOMAIN}/outlook&response_type=code&scope=${encodeURIComponent(SCOPE)}&state=12345`);
            window.onfocus = () => {
              const { credentialsExist } = checker
              if (credentialsExist) {
                callback?.();
                this.selectedDates = [];
              } else {
                this.verifiedMessage(
                  this.$t("project.projectUser.components.shortList.components.selectDateModal.verifiedMessageOutlook"),
                  this.$t("project.projectUser.components.shortList.components.selectDateModal.verifiedMessageOutlookConfirm"),
                  this.$t("project.projectUser.components.shortList.components.selectDateModal.verifiedMessageOutlookDecline")
                ).then(async (result) => {
                  if (result.isConfirmed) {
                    await this.authorizeMixin();
                    callback?.()
                  } else {
                    window.onfocus = null;
                    this.hide();
                  }
                });
              }
            };
          });
      }

      // Google 
      else if (!credentialsExist && this.defaultService == 2) {
        try {
          this.warningMesage(
            this.$t("project.projectUser.components.shortList.components.meetModal.warningMesage"),
            3500
          ).then(async () => {
            await this.axios.get(technicalInterviewGoogle.authorize).then((res) => {
              window.open(res.data.data);
            }).then(() => callback?.());

          });
        } catch (err) {
          console.log(err);
          throw err;
        }
      } else {
        callback?.();
      }
    },

    listFormatController(val) {
      try {
        return JSON.parse(val);
      } catch (error) {
        if (Array.isArray(val)) return val;
        else {
          return [];
        }
      }
    },
    signOut() {
      this.axios.post(auth.signOut).then(() => {
        this.$store.commit("signOut");
        this.$router.push("/");
      });
    },
  },
  filters: {
    formatDate(val) {
      if (val) return moment(val).format("LLL");
      else return i18n.t("noDate");
    },
    formatDateYearMonth(val) {
      if (val) return moment(val).format("LL");
      else return i18n.t("noDate");
    },
  },
};
