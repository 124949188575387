<template>
  <div class="flex py-8 border-b border-gray-200 max-md:flex-wrap gap-[97px]">
    <div class="w-6/12 max-md:w-full">{{ $t('project.projectUser.components.shortList.components.steps.interviewType.interviewTypeSelect') }}</div>
    <div class="w-6/12 h-full max-md:w-full flex flex-col gap-4">
      <multiselect
        v-model="selectedInterviewTypes"
        :options="interviewTypeNames"
        :taggable="true"
        @tag="addNewInterviewType"
        :placeholder="$t('project.projectUser.components.shortList.components.steps.interviewType.interviewTypeSelectPlaceholder')"
      >
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import axios from "axios";
import { settingGeneral } from "@/networking/urlmanager";
export default {
  name: "AddInterviewType",
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedInterviewTypes: [],
      interviewTypes: [],
    };
  },
  created() {
    this.fetchInterviewTypes();
  },
  methods: {
    async fetchInterviewTypes() {
      try {
        const response = await this.axios.get(settingGeneral.getInterviewType);
        this.interviewTypes = response.data.data;
      } catch (error) {
        console.error("Error fetching interview types:", error);
      }
    },
    async addNewInterviewType(newType) {
      try {
        const exists = this.interviewTypes.some(
          (type) => type.name === newType
        );
        if (!exists) {
          const response = await this.axios.post(
            settingGeneral.interviewTypeEdit,
            {
              name: newType,
            }
          );
          this.fetchInterviewTypes()
          this.interviewTypes.push(response.data.data);
          this.selectedInterviewTypes.push(newType);
        }
      } catch (error) {
        console.error("Error adding new interview type:", error);
      }
    },
  },
  computed: {
    interviewTypeNames() {
      return this.interviewTypes
        .filter((type) => type && type.name)
        .map((type) => type.name);
    },
  },
};
</script>

<style>
@import "vue-multiselect/dist/vue-multiselect.min.css";
</style>
