var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"add-project-publications","height":_vm.isMobile ? '100%' : 'auto',"width":_vm.isMobile ? '100%' : '35%',"scrollable":true}},[_c('div',{staticClass:"flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"},[_c('h1',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$t("cv.profile.projectsPublications.components.add.add"))+" ")]),_c('button',{on:{"click":function($event){return _vm.hide()}}},[_c('i',{staticClass:"fas fa-times"})])]),_c('form',{staticClass:"p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();_vm.isEdit ? _vm.add() : _vm.save()}}},[_c('div',{staticClass:"space-y-4 pb-3"},[_c('customInput',{ref:"nameInput",attrs:{"type":"text","min":"2","max":"55","required":true,"is-error":true,"placeholder":_vm.$t(
            'cv.profile.projectsPublications.components.add.projectPlacholder'
          ),"title":_vm.$t('cv.profile.projectsPublications.components.add.project')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('customInput',{attrs:{"type":"url","placeholder":_vm.$t(
            'cv.profile.projectsPublications.components.add.projectUrlPLacholder'
          ),"title":_vm.$t('cv.profile.projectsPublications.components.add.projectUrl')},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_c('customInput',{attrs:{"type":"date","placeholder":_vm.$t('cv.profile.projectsPublications.components.add.dataPlacholder'),"title":_vm.$t('cv.profile.projectsPublications.components.add.data')},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('customInput',{ref:"textAreaRef",attrs:{"type":"textarea","max":"500","required":true,"is-error":true,"textareaClass":"resize-none","placeholder":_vm.$t(
            'cv.profile.projectsPublications.components.add.explanationPlacholder'
          ),"title":_vm.$t('cv.profile.projectsPublications.components.add.explanation'),"rows":4},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('requiredField'),_c('div',{staticClass:"flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"},[_c('div',{staticClass:"w-full"},[_c('buttonItem',{attrs:{"load":_vm.load,"name":_vm.$t('cv.profile.projectsPublications.components.add.save'),"buttonClass":"w-full"}})],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }