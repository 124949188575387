<template>
  <modal
    name="edit-experience"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.workExperience.components.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="isEdit ? add() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <multiSelectVue
          :maxLength="100"
          :placeholder="
            $t('cv.profile.workExperience.components.add.companyNamePlacholder')
          "
          :label="$t('cv.profile.workExperience.components.add.companyName')"
          ref="companyRef"
          v-model="companyName"
          :required="true"
          :getOptions="getCorporation"
        />

        <multiSelectVue
          :maxLength="100"
          :placeholder="$t('cv.profile.workExperience.components.add.position')"
          :label="
            $t('cv.profile.workExperience.components.add.positionPlacholder')
          "
          ref="positionRef"
          v-model="PositionTitle"
          :required="true"
          :getOptions="getPosition"
        />

        <div class="grid grid-cols-2 gap-2">
          <customInput
            v-model="periotStart"
            type="date"
            :required="true"
            :is-error="true"
            ref="periotStart"
            :placeholder="
              $t('cv.profile.workExperience.components.edit.startPlacholder')
            "
            :title="$t('cv.profile.workExperience.components.edit.start')"
          />
          <customInput
            v-model="periotEnd"
            type="date"
            :placeholder="
              $t('cv.profile.workExperience.components.edit.finish')
            "
            :title="$t('cv.profile.workExperience.components.edit.finish')"
          />
        </div>
        <customInput
          v-model="doesItContinue"
          type="select"
          :selectList="[
            {
              value: 1,
              name: $t(
                'cv.profile.schoolAndEducation.components.add.continues'
              ),
            },
            {
              value: 2,
              name: $t(
                'cv.profile.schoolAndEducation.components.add.completed'
              ),
            },
          ]"
          :placeholder="
            $t('cv.profile.workExperience.components.edit.levelPlacholder')
          "
          :title="$t('cv.profile.workExperience.components.edit.level')"
        />

        <customInput
          v-model="explanation"
          type="textarea"
          max="500"
          :rows="5"
          :placeholder="
            $t(
              'cv.profile.workExperience.components.edit.explanationPlacholder'
            )
          "
          :title="$t('cv.profile.workExperience.components.edit.explanation')"
        />

        <requiredField />
        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.workExperience.components.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import moment from "moment";
import requiredField from "@/components/requiredField.vue";

import {
  utility,
  workExperience,
  utilityPosition,
} from "@/networking/urlmanager.js";

export default {
  name: "addProject",
  props: ["isEdit", "Detail", "index"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      companyName: null,
      PositionTitle: null,

      periotStart: "",
      periotEnd: "",

      experienceMounth: 0,
      doesItContinue: 0,
      explanation: "",
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.rowId = this.Detail.id;
        this.companyName = this.Detail.company;
        this.PositionTitle = this.Detail.position;

        if (this.Detail.formatDate) {
          const data = this.Detail.formatDate.split("-");

          this.periotStart = data[0]
            ? moment(data[0]).format("YYYY-MM-DD")
            : "";
          this.periotEnd = data[1].trim()
            ? moment(data[1]).format("YYYY-MM-DD")
            : "";

          const diffMonths = moment(
            data[1].trim() ? this.periotEnd : moment().format("YYYY-MM-DD")
          ).diff(moment(this.periotStart), "months", true);

          this.experienceMounth = diffMonths.toFixed(2);
        }

        this.doesItContinue = this.Detail.doesItContinue;
        this.explanation = this.Detail.description;
      }, 50);
      this.$modal.show("edit-experience");
    },
    hide() {
      this.$modal.hide("edit-experience");
    },
    save() {
      this.load = true;

      this.checkErrors();

      this.$emit("loadChance", true);

      let format = this.getDateFormatter();

      this.$store.commit("editExperience", {
        index: this.index,
        list: {
          company: this.companyName,
          position: this.PositionTitle,
          duration: format.date,
          formatDate: format.date,
          description: this.explanation,
          doesItContinue: this.doesItContinue,
        },
      });

      this.doesItContinue = 0;
      this.companyName = "";
      this.PositionTitle = "";
      this.periotStart = "";
      this.periotEnd = "";
      this.explanation = "";

      if (!this.isEdit) {
        this.successMessage();
      }

      this.load = false;
      this.$emit("loadChance", true);

      if (this.isEdit) {
        this.$emit("refresh", true);
      }
      this.hide();
    },
    add() {
      this.load = true;

      this.checkErrors();

      let format = this.getDateFormatter();

      const experince = this.$store.state.extractDetail.workexperience;
      this.axios
        .post(workExperience.edit, {
          cvId: this.$route.params.id,
          list: {
            company: this.companyName,
            position: this.PositionTitle,
            duration: format.date,
            formatDate: format.date,
            description: this.explanation,
            doesItContinue: this.doesItContinue,
          },
          rowId: this.rowId,
          experinceList: JSON.stringify(experince),
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.save();
          this.load = false;
          this.hide();
        })
        .catch((err) => {
          console.log(err);
          this.authController(err);
          this.load = false;
        });
    },
    async getPosition() {
      const response = await this.axios.get(utilityPosition.getAll);
      return response.data.data;
    },
    async getCorporation() {
      const response = await this.axios.get(utility.getCorporationAll);
      return response.data.data;
    },
    getDateFormatter() {
      if (!this.periotStart) {
        return { date: 0, experience: 0 };
      }
      const startDate = moment(this.periotStart).format("YYYY.MM.DD");
      const endDate = this.endDate();

      return {
        date: `${startDate} - ${endDate == "Invalid date" ? "" : endDate}`,
      };
    },
    endDate() {
      try {
        const endDate = moment(this.periotEnd).format("YYYY.MM.DD");
        return endDate;
      } catch {
        return moment().format("YYYY.MM.DD");
      }
    },
    checkErrors() {
      this.$refs.companyRef.validate();
      this.$refs.positionRef.validate();
      this.$refs.periotStart.validateInput();
    },
  },
};
</script>
