<template>
  <div class="bg-white shadow-md rounded-lg overflow-hidden">
    <div v-if="list.length > 0" class="min-h-[300px] max-h-[500px] overflow-auto">
      <table class="w-full">
        <thead class="bg-gray-100 border-b sticky top-0 z-10">
          <tr class="text-gray-600 text-sm font-semibold">
            <th class="p-5 text-left whitespace-nowrap">
              {{
      $t(
        "project.projectUser.components.shortList.components.steps.interviewList.header.interviewTitle"
      )
    }}
            </th>
            <th class="p-5 text-left whitespace-nowrap">
              {{
        $t(
          "project.projectUser.components.shortList.components.steps.interviewList.header.type"
        )
      }}
            </th>
            <th class="p-5 text-left whitespace-nowrap">
              {{
        $t(
          "project.projectUser.components.shortList.components.steps.interviewList.header.date"
        )
      }}
            </th>
            <th class="p-5 text-left whitespace-nowrap">
              {{
        $t(
          "project.projectUser.components.shortList.components.steps.interviewList.header.interviewPlace"
        )
      }}
            </th>
            <th class="p-5 text-left whitespace-nowrap">
              {{
        $t(
          "project.projectUser.components.shortList.components.steps.interviewList.header.status"
        )
      }}
            </th>
            <th class="p-5 text-left whitespace-nowrap">
              {{
        $t(
          "project.projectUser.components.shortList.components.steps.interviewList.header.action"
        )
      }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in processedList" :key="item.id"
            class="hover:bg-gray-50 text-sm transition-colors duration-200 border-b last:border-b-0">
            <td class="px-5 py-2 font-medium text-gray-800 whitespace-nowrap">
              {{ item.title }}
            </td>
            <td class="px-5 py-2 text-gray-600 whitespace-nowrap">
              {{ item.typeName }}
            </td>
            <td class="px-5 py-2 text-gray-600 whitespace-nowrap">
              {{ formatDate(item.createdAt) }}
            </td>
            <td class="px-5 py-2 whitespace-nowrap">
              <span class="px-2 py-1 rounded-full text-xs font-medium" :class="item.isOnline
      ? 'bg-blue-100 text-blue-800'
      : 'bg-green-100 text-green-800'
      ">
                {{
      item.isOnline
        ? $t(
          "project.projectUser.components.shortList.components.steps.interviewList.online"
        )
        : $t(
          "project.projectUser.components.shortList.components.steps.interviewList.faceToFace"
        )
    }}
              </span>
            </td>
            <td class="px-5 py-2 whitespace-nowrap">
              <span class="px-2 py-1 rounded-full text-xs font-medium" :class="statuName(item.history[item.history.length - 1].status).class
      ">
                {{
      statuName(item.history[item.history.length - 1].status).name
    }}
              </span>
            </td>
            <td class="px-5 py-2 flex items-center justify-between space-x-3 whitespace-nowrap">
              <asyncButton v-if="item.history[item.history.length - 1].status == 3 ||
      item.history[item.history.length - 1].status == 4 ||
      item.history[item.history.length - 1].status == 5 ||
      item.history[item.history.length - 1].status == 6"
                :buttonBodyClass="'flex flex-row-reverse justify-center items-center gap-2'"
                :buttonClass="'rounded-md text-xs text-white px-3 py-2 bgcol'" :text="getButtonText(item)
      " :awesome="'fa-solid fa-arrow-right text-gray-500 hover:text-gray-700 cursor-pointer'"
                @click="handleButtonClick(item)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import asyncButton from "@/components/button.vue";
export default {
  name: "InterviewList",
  components: {
    asyncButton,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    processedList() {
      return this.list
        .map((item) => {
          const type = this.types.find((t) => t.value === item.type);
          return {
            ...item,
            typeName: type ? type.name : "Unknown",
          };
        })
        .reverse();
    },
  },
  methods: {
    getButtonText(item) {
      const status = item.history[item.history.length - 1].status;

      switch (status) {
        case 3:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.sendAppointment"
          );
        case 4:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.sendNewDates"
          );
        case 5:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.resendEmail"
          );
        case 6:
          return this.$t(
            "project.projectUser.components.shortList.components.steps.interviewList.sendAppointmentAgain"
          );
        default:
          return "";
      }
    },
    handleButtonClick(item) {
      const status = item.history[item.history.length - 1].status;

      switch (status) {
        case 3:
          // this.$emit("resendEmail", item);

          this.$emit("successful", item);
          break;
        case 4:
          // this.$emit("resendEmail", item);
          this.$emit("unsuccessful", item);
          break;
        case 5:

          this.$emit("resendEmail", item);
          break;
        case 6:
          this.$emit("successful", item, true);
          break;
        // default:
        //   this.$emit("resendEmail", item);
        //   break;
      }
    },
    formatHistory(history) {
      if (!Array.isArray(history)) return "";

      return history
        .map((entry) => {
          const label = entry.isCompany
            ? this.$t(
              "project.projectUser.components.shortList.components.steps.interviewList.company"
            )
            : this.$t(
              "project.projectUser.components.shortList.components.steps.interviewList.candidate"
            );

          return entry.dates
            .map((dateEntry) => {
              const date = dateEntry.date
                ? this.formatDate(dateEntry.date)
                : "";
              return `${label}: ${date}`;
            })
            .join(", ");
        })
        .join(", ");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { day: "2-digit", month: "long", year: "numeric" };
      return new Intl.DateTimeFormat("tr-TR", options).format(date);
    },
    statuName(val) {
      switch (val) {
        case 1:
          return {
            name: this.$t("project.tableInterview.stateName.waiting"),
            class: "",
          };
        case 2:
          return {
            name: this.$t("project.tableInterview.stateName.interviewSent"),
            class: "bg-green-100 text-green-500",
          };
        case 3:
          return {
            name: this.$t("project.tableInterview.stateName.dateConfirmed"),
            class: "bg-green-100 text-green-500",
          };
        case 4:
          return {
            name: this.$t("project.tableInterview.stateName.dateRejected"),
            class: "bg-red-100 text-red-500",
          };
        // case 5:
        //   return {
        //     name: this.$t("project.tableInterview.stateName.meetCreated"),
        //     class: "bg-green-100 text-green-500",
        //   };
        // case 6:
        //   return {
        //     name: this.$t("project.tableInterview.stateName.hired"),
        //     class: "bg-green-100 text-green-500",
        //   };
        case 7:
          return {
            name: this.$t("project.tableInterview.stateName.inadequate"),
            class: "bg-red-100 text-red-500",
          };
        case 5:
          return {
            name: this.$t(
              "project.tableInterview.stateName.interviewCouldNotBeSent"
            ),
            class: "bg-yellow-100 text-yellow-600",
          };
        case 6:
          return {
            name: this.$t("project.tableInterview.stateName.interviewCouldNotBeSent"),
            class: "bg-yellow-100 text-yellow-600",
          };
        default:
          return { name: "", class: "" };
      }
    },
  },
};
</script>

<style scoped>
.group:hover .tooltip {
  @apply opacity-100;
}
</style>
