<template>
  <div>
    <sideModal ref="shortListSide" modalClass="w-8/12 max-xl:w-10/12 xl:w-[82%] max-sm:w-full" :isHeader="true"
      :headerTitle="$route.query.projectName +
      ' ' +
      $t('project.projectUser.components.shortList.index.shortList')
      " :isClosable="false">
      <template v-slot:external>

        <steps :selected="selected" ref="stepsRef" />
        <addPointPanel ref="changeScorePoint" :default-note="note" :score="score" :statusId="statusId"
          :title="changeTitle" :fullname="fullname" :rowId="rowId" :typeId="typeId" :isDisabled="isDisabled"
          @refresh="$refs.tableItem.getAll()" />

        <personelInformationModal ref="personelInformationModal" :Detail="selected" />
      </template>

      <template v-slot:content>
        <div class="overflow-y-auto scroltype h-[85vh] p-2 max-sm:p-5">
          <div class="my-5 w-full" style="height: calc(100% - 100px)">
            <div>
              <tableInterview @showDateModal="stepsShow" 
                @showpersonelInformation="(r) => showpersonelInformation(r)" data-v-step="1" ref="tableItem"
                @changePoint="(val) => setPoint(val)" @isDelete="(r) => (isDelete = r)" @editQuestion="editQuestion"
                @List="(r) => (List = r)" @refresh="$emit('refresh', true)" />
            </div>
          </div>
        </div>
      </template>
    </sideModal>
  </div>
</template>

<script>
import tableInterview from "./components/table/tableInterview.vue";
import addPointPanel from "./components/table/changePointAndNote.vue";
import personelInformationModal from "./components/personelInformation/index.vue";
import steps from "./components/technicalInterview/steps.vue";
export default {
  name: "short-list-page",
  components: {
    tableInterview,
    addPointPanel,
    personelInformationModal,
    steps,
  },
  data() {
    return {
      load: false,
      List: [],
      selected: {},
      isDelete: 1,

      changeTitle: "",
      rowId: "",
      typeId: "",
      fullname: "",

      score: "",
      note: "",
      statusId: 0,
      isDisabled: false,
    };
  },
  methods: {
    setPoint(val) {
      this.typeId = val.typeId;
      this.statusId = val.detail.statusId;
      this.rowId = val.detail.id;
      this.fullname = val.detail.fullname;
      if (val.typeId == 1) {
        this.changeTitle = this.$t(
          "project.projectUser.components.shortList.index.points.preInterviewScore"
        );
        this.score = val.detail.interviewPoint;
        this.note = val.detail.interviewNote;
        this.isDisabled = true;
      } else if (val.typeId == 2) {
        this.changeTitle = this.$t(
          "project.projectUser.components.shortList.index.points.hrInterviewScore"
        );
        this.score = val.detail.ikPoint;
        this.note = val.detail.ikNote;
        this.isDisabled = false;
      } else if (val.typeId == 3) {
        this.changeTitle = this.$t(
          "project.projectUser.components.shortList.index.points.technicalInterviewScore"
        );
        this.score = val.detail.technicalPoint;
        this.note = val.detail.technicalNote;
        this.isDisabled = false;
      } else if (val.typeId == 4) {
        this.changeTitle = this.$t(
          "project.projectUser.components.shortList.index.points.assessmentScore"
        );
        this.score = val.detail.assessmentPoint;
        this.note = val.detail.assessmentNote;
        this.isDisabled = false;
      }
      this.$refs.changeScorePoint.show();
    },
    async toggleSidebar() {
      this.load = true;
      this.List = [];
      this.$refs.shortListSide.show();
      setTimeout(async () => {
        await this.$refs.tableItem.getAll();
        this.load = false;
      }, 50);
    },
    editQuestion(val) {
      this.selected = val;
      this.$refs.edit.show();
    },
    stepsShow(val) {
      this.selected = val;
      this.$nextTick(() => {
        this.$refs.stepsRef.show();
      });
    },
    meetModalShow(val) {
      this.selected = val;
      this.$nextTick(() => {
        this.$refs.meetModal.show();
      });
    },
    showpersonelInformation(val) {
      this.selected = val;
      this.$refs.personelInformationModal.show();
    },
  },
};
</script>
<style>
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 500;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.sidebar-open {
  transform: translateX(0);
}

.scroltype::-webkit-scrollbar {
  width: 6px;
}

.scroltype::-webkit-scrollbar-thumb {
  background: #344ca0;
  border-radius: 10px;
}
</style>
