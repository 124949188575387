<template>
  <modal name="interview-diversity" :height="'auto'" :width="isMobile ? '100%' : '60%'" :scrollable="true"
    @closed="hide()">
    <div class="flex justify-between items-center card-header p-5 bgcol w-full xl:rounded-t text-white">
      <h1 class="text-lg">
        {{
    $t(
      "project.projectUser.components.shortList.components.selectDateModal.modalHeaderTitle"
    )
  }}
      </h1>
      <asyncButton v-if="step == 0" @click="nextStep" buttonBodyClass="text-black"
        containerBackground="bg-gray-100 text-black border border-white"
        awesome="fa-solid fa-plus text-xs text-black font-semibold" :text="$t('project.projectUser.components.shortList.components.steps.add')
    " />
      <!-- @handleClick="nextStep" -->
      <button v-if="step !== 0" @click.stop="hide">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <tableLoader v-if="step == 0" class="min-h-[300px]" :load="load" :length="list.length" colspan="7" />
    <InterviewList @successful="successful" @unsuccessful="unsuccessful" @resendEmail="resendEmail" :list="list"
      :types="types" v-if="step == 0 && !load" @add="nextStep" />
    <InterviewType :types="types" @submit="saveInterview" v-if="step == 1" />
    <calendarStep :interviewData="interviewData" :detail="selected" ref="calendarStepRef" v-show="step == 2"
      @close="hide" />
    <!-- :load="calendarLoad" -->
    <!-- :meetDetail:="selected" -->
    <InterviewMethod ref="interviewMethodRef" :isOnline="isOnline" v-show="step == 3" @refresh="hide" />
    <historyCalendar :detail="selected" v-show="step == 4" @close="hide" ref="historyCalendarRef" />
    <!-- <div
      v-if="step !== 0 && step !== 3"
      class="flex pb-5 flex-1 justify-center"
    >
      <p class="text-center font-bold textcol">{{ step }} / 2</p>
    </div> -->
  </modal>
</template>

<script>
import tableLoader from "@/components/table-loader.vue";
import calendarStep from "./calander/index.vue";
import InterviewList from "./InterviewList/InterviewList.vue";
import InterviewType from "./InterviewType/InterviewType.vue";
import InterviewMethod from "./InterviewMethod/InterviewMethod.vue";
import {
  technicalInterviewGeneral,
  settingGeneral,
} from "@/networking/urlmanager";
import historyCalendar from "./historyCalendar/index.vue";
import asyncButton from "@/components/button.vue";
export default {
  name: "steps-modal",
  props: ["selected"],
  components: {
    tableLoader,
    calendarStep,
    InterviewMethod,
    InterviewList,
    InterviewType,
    asyncButton,
    historyCalendar,
  },
  data() {
    return {
      load: false,
      step: 3,
      isModalVisible: false,
      list: [],
      isOnline: null,
      interviewData: {},
      types: [],
      // calendarLoad: false,
    };
  },
  watch: {
    selected: {
      immediate: true,
      handler(newValue) {
        // if (newValue && newValue.cvId && newValue.projectId) {
        //   console.log("selected", newValue);
        //   this.getInterviews(this.selected.cvId, this.selected.projectId);
        // }
      },
    },
  },
  methods: {
    async successful(item, isResend = false) {
      this.$store.state.interviewId = item.id;
      this.$refs.interviewMethodRef.selectedAddress = this.selected;
      this.$refs.interviewMethodRef.isOnline = item.isOnline;
      this.$refs.interviewMethodRef.isResend = isResend;
      this.$refs.interviewMethodRef.fetchAddressDetails();
      this.step = 3;
    },
    async unsuccessful(item) {
      this.$refs.historyCalendarRef.interviewHistory = item.history;
      this.$refs.historyCalendarRef.interviewId = item.id;
      await this.$refs.historyCalendarRef.initializeHistoryCalendar();
      this.step = 4;
    },
    async resendEmail(item) {
      this.load = true;

      try {
        const response = await this.axios.post(technicalInterviewGeneral.interviewEmailSendAgain, {
          interviewId: item.id,
          datesHistory: item.history,
          isOnline: item.isOnline,
        });
        this.getInterviews(this.selected.cvId, this.selected.projectId);
      } catch (error) {
        console.error("Error resending email:", error);

      } finally {
        this.load = false;
      }
    },
    async saveInterview(interviewData) {
      this.interviewData = interviewData;

      if (
        this.$refs.calendarStepRef &&
        this.$refs.calendarStepRef.initializeCalendar
      ) {
        await this.$refs.calendarStepRef.initializeCalendar();
        this.step = 2;
      } else {
        console.error("calendarStepRef is not available or not loaded.");
      }
    },
    async getInterviews(cvId, projectId) {
      this.load = true;
      try {
        const response = await this.axios.get(
          technicalInterviewGeneral.getInterviews,
          {
            params: {
              cvId,
              projectId,
            },
          }
        );

        this.list = response.data.data;
      } catch (error) {
        this.load = false;
        console.error("Error fetching interviews:", error);
      } finally {
        this.load = false;
      }
    },
    async show() {
      this.$modal.show("interview-diversity");
      this.step = 0;
      this.isModalVisible = true;
      this.getTypes();
      if (this.selected && this.selected.cvId && this.selected.projectId) {
        await this.getInterviews(this.selected.cvId, this.selected.projectId);
      }
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    },
    async hide() {
      this.$modal.hide("interview-diversity");
      this.isModalVisible = false;
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange
      );
    },
    async getTypes() {
      try {
        const response = await this.axios.get(settingGeneral.getInterviewType);
        this.types = response.data.data.map((type) => ({
          value: type.id,
          name: type.name,
        }));
      } catch (error) {
        console.error("Mülakat türleri alınırken hata oluştu:", error);
        this.$toast.error(
          this.$t(
            "project.projectUser.components.shortList.components.steps.interviewType.interviewTypeLoadingError"
          ),
          {
            position: "top-right",
          }
        );
      }
    },
    nextStep() {
      this.step++;
    },
  },
};
</script>
