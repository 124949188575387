<template>
  <div
    @mousedown="toggleDown"
    class="my-10 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 relative max-sm:my-8"
  >
    <uploadPanel ref="filtering" data-v-step="0" />
    <filtering
      ref="filtering"
      :data="List"
      @deleteFilter="() => ((this.isFilter = 1), getAll())"
      @filterAll="(r) => getFilter(r)"
      :currentPage="currentPage"
      @load="chanceLoad"
    />
    <cvListModal ref="modal" @refresh="getAll" />
    <history ref="history" :Detail="selected" />

    <div class="flex w-full justify-end gap-2 items-center">
      <div v-if="checkedList.length > 0">
        <div v-if="!isAllStatus">
          <label for="">{{ $t("cv.index.tableTopList.state") }}</label>
          <select
            v-model="status"
            class="border pr-2 py-0.5 text-black border-[#182451] rounded"
            @change="(val) => allChangeStatus(val)"
          >
            <option disabled value="0">
              {{ $t("cv.index.tableTopList.yourChoice") }}
            </option>
            <option value="1">{{ $t("cv.index.tableTopList.active") }}</option>
            <option value="4">
              {{ $t("cv.index.tableTopList.unsuitable") }}
            </option>
            <option value="2">{{ $t("cv.index.tableTopList.deleted") }}</option>
          </select>
        </div>
        <div v-else class="pr-2 py-0.5 text-black rounded">
          <i class="fas fa-spinner fa-spin"></i>
          {{ $t("cv.index.tableTopList.loading") }}
        </div>
      </div>
    </div>

    <div class="tableOverflow scroltypey mt-5" data-v-step="1">
      <div class="tableWidth">
        <div class="tableContainer">
          <div class="tableHead">
            <ul class="tableUl">
              <li class="w-1/12">
                <input type="checkbox" @change="(val) => selectAll(val)" />
              </li>
              <li class="w-3/12">{{ $t("cv.index.tableList.resume") }}</li>
              <li class="w-3/12 max-md:w-3/12">
                {{ $t("cv.index.tableList.position") }}
              </li>
              <li class="w-5/12 max-md:w-5/12 flex justify-end">
                <div class="w-[300px] tableSearch relative">
                  <input
                    v-model="search"
                    @keypress.enter="getSearch()"
                    type="text"
                    class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                    :placeholder="$t('cv.index.tableList.cvPlaceholder')"
                  />
                  <button class="px-3 textcol absolute right-0">
                    <i
                      @click="getSearch()"
                      class="fa-solid fa-magnifying-glass"
                    ></i>
                  </button>
                  <button
                    @click="projectFilter = !projectFilter"
                    class="absolute right-6"
                  >
                    <div class="relative group flex justify-center">
                      <img
                        v-show="isFilter == 1"
                        src="@/assets/cv/filteredit.svg"
                        alt=""
                        class="w-5 mr-5 object-contain"
                        data-v-step="2"
                      />
                      <img
                        v-show="isFilter == 2"
                        src="@/assets/cv/filter.svg"
                        alt=""
                        class="w-5 mr-5 object-contain"
                        data-v-step="2"
                      />
                    </div>
                  </button>
                  <div
                    @mousedown.stop="projectFilter = true"
                    :class="{
                      'invisible opacity-0 transition-all ease-in duration-100':
                        !projectFilter,
                    }"
                    class="w-full z-50 rounded mt-1 px-2 text-xs absolute bg-white top-full border border-gray-300 text-black shadow-md"
                  >
                    <button
                      v-for="(item, index) in projectState"
                      :key="index"
                      @click="filterButton(item)"
                      :class="{ 'text-blue-700': item.active }"
                      class="w-full py-2 border-b border-gray-100 text-left"
                    >
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="tableBody scroltype">
            <div class="tableBodyContainer">
              <ul
                v-for="(item, index) in List"
                :key="index"
                class="tableBodyUl"
              >
                <li
                  class="cursor-pointer select-none w-1/12 py-2.5 flex gap-5 items-center"
                >
                  <input
                    type="checkbox"
                    v-model="checkedList"
                    :name="index"
                    :id="index"
                    :value="index"
                    class="mr-2"
                  />

                  <!-- <p class="w-[10px]">{{ currentPage * 15 + (index + 1) }} </p> -->
                  <button
                    :disabled="item.stateId == 4"
                    @click="cvFavorite(item, index)"
                  >
                    <i
                      v-if="item.isFavorite == 2"
                      class="fa-solid fa-star text-base"
                    ></i>
                    <i v-else class="fa-regular fa-star text-base"></i>
                  </button>
                </li>

                <li
                  @click="$router.push(`/cv/profile/${item.id}`)"
                  class="cursor-pointer select-none w-3/12 py-2.5 flex items-center gap-2"
                  id=""
                >
                  <div
                    v-if="isWithinTwoDays(item.addDate)"
                    class="bg-blue-100 text-blue-500 px-1 rounded-md text-xs"
                  >
                    Yeni
                  </div>
                  {{ formatText(item.fullname) }}
                </li>

                <li
                  @click="$router.push(`/cv/profile/${item.id}`)"
                  class="cursor-pointer select-none w-3/12 max-md:w-3/12 py-2.5 text-justify pr-5"
                >
                  {{
                    item.position
                      ? formatText(item.position)
                      : $t("cv.index.unspecified")
                  }}
                </li>

                <li
                  class="w-5/12 max-md:w-5/12 flex gap-6 justify-between px-3 items-center"
                >
                  <div class="w-5/12">
                    <select
                      data-v-step="3"
                      class="border border-[#182451] rounded w-[125px]"
                      v-if="!item.changeStatus"
                      v-model="item.temporaryStateId"
                      @change="onStatusChange($event, item)"
                    >
                      <option value="1">
                        {{ $t("cv.index.tableList.active") }}
                      </option>
                      <option value="4">
                        {{ $t("cv.index.tableList.unsuitable") }}
                      </option>
                      <option value="2">
                        {{ $t("cv.index.tableList.deleted") }}
                      </option>
                    </select>
                    <div
                      v-else
                      class="flex items-center gap-1 text-sm w-[125px]"
                    >
                      <i class="fas fa-spinner fa-spin"></i>
                      <label class="text-sm">{{
                        $t("cv.index.tableList.recorded")
                      }}</label>
                    </div>
                  </div>
                  <p class="w-[140px]">{{ item.addDate | getDate }}</p>
                  <div class="flex gap-4 w-[100px] items-center">
                    <button
                      @click="
                        () => ((selected = item), $refs.history.toggleSidebar())
                      "
                      class="relative group flex justify-center"
                    >
                      <i class="fa-solid fa-clock-rotate-left w-4"></i>

                      <tooltip
                        :title="$t('cv.index.toolTip.history')"
                        tipClass="z-50 w-[160px] !-right-1 !top-6 "
                        rotateClass="ml-[140px]"
                        :top="true"
                      />
                    </button>

                    <div class="relative group flex justify-center">
                      <router-link
                        :to="`/cv/profile/${item.id}`"
                        class="block min-w-4 w-4 h-full"
                        data-v-step="4"
                      >
                        <img
                          class="min-w-4 w-4"
                          src="@/assets/cv/document-icon.svg"
                          alt=""
                        />
                      </router-link>
                      <tooltip
                        :title="$t('cv.index.toolTip.detail')"
                        tipClass="z-50 w-[160px] !-right-1 !top-6 "
                        rotateClass="ml-[140px]"
                        :top="true"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <tour
      :steps="steps"
      :moduleId="2"
      v-if="!$store.state.userData.tourModuleId.includes(2)"
    />
    <pagination
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>

<script>
import uploadPanel from "./components/uploadPanel";
import history from "./components/history.vue";
import tableLoader from "@/components/table-loader.vue";
import pagination from "@/components/pagination.vue";

import filtering from "./components/filter-panel.vue";
import moment from "moment";
import "moment/locale/tr";
import { cv } from "@/networking/urlmanager";
import { cvtour } from "@/tourmodel/tour.js";
import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";
import cvListModal from "./components/cvListSide/index.vue";

export default {
  name: "cv-page",
  components: {
    tableLoader,
    uploadPanel,
    history,
    filtering,
    pagination,
    tour,
    tooltip,
    cvListModal,
  },
  data() {
    return {
      temporaryStateId: null, // Geçici seçimi burada tutuyoruz
      List: [],
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      rowId: "",
      completedModels: [],
      projectFilter: false,
      stateId: "",
      blackList: "",
      load: false,
      projectState: [
        {
          id: 3,
          name: this.$t("cv.index.filterMenu.active"),
          active: false,
        },
        {
          id: 1,
          name: this.$t("cv.index.filterMenu.favorite"),
          active: false,
        },
        {
          id: 4,
          name: this.$t("cv.index.filterMenu.unsuitable"),
          active: false,
        },
        {
          id: 2,
          name: this.$t("cv.index.filterMenu.filterMenuTitle"),
          active: false,
        },
      ],
      steps: cvtour,
      isFilter: 1,
      checkedList: [],
      isAllStatus: false,
      status: 0,

      selected: "",
    };
  },
  methods: {
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    isWithinTwoDays(dateString) {
      const now = moment();
      const date = moment(dateString);
      const diffDays = now.diff(date, "days");
      return diffDays <= 2;
    },
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(cv.getAll, {
          params: {
            page: this.$route.params.currentPage,
            search: this.search,
            isFavorite: this.stateId,
            blackList: this.blackList,
            dataCount: this.dataCount,
          },
        })
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.totalCount = totalCount;
          this.pageCount = pageCount;

          this.List = detail.map((r) => {
            return {
              ...r,
              temporaryStateId: r.stateId,
              changeStatus: false,
            };
          });

          this.load = false;
          this.checkedList = [];
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },

    allChangeStatus(val) {
      this.verifiedMessage(
        this.$t("cv.index.warningMessage"),
        this.$t("cv.index.yesChange")
      ).then((result) => {
        if (result.isConfirmed) {
          this.isAllStatus = true;
          this.axios
            .post(cv.allChangeStatus, {
              list: JSON.stringify(
                this.checkedList.map((r) => {
                  return {
                    id: this.List[r].id,
                    fullname: this.List[r].fullname,
                  };
                })
              ),
              stateId: val.target.value,
            })
            .then(() => {
              this.status = 0;
              this.getData();
              this.isAllStatus = false;
            })
            .catch((err) => {
              this.isAllStatus = false;
              this.authController(err);
            });
        }
      });
    },
    selectAll(val) {
      if (val.target.checked) {
        for (let index = 0; index < this.List.length; index++) {
          this.checkedList.push(index);
        }
      } else {
        this.checkedList = [];
      }
    },
    chanceLoad() {
      this.List = [];
      this.load = true;
    },
    getFilter(val) {
      this.totalCount = val.totalCount;
      this.pageCount = val.pageCount;
      this.List = val.detail.map((r) => {
        return {
          ...r,
          temporaryStateId: r.stateId,
          changeStatus: false,
        };
      });
      this.isFilter = 2;
      this.load = false;
    },
    onStatusChange(event, item) {
      const newValue = event.target.value;
      this.verifiedMessage(
        "'" + item.fullname + this.$t("cv.index.verifiedMessage"),
        this.$t("cv.index.yesChange")
      ).then((result) => {
        if (result.isConfirmed) {
          item.stateId = newValue;
          this.changeStatus(newValue, item);
        } else {
          item.temporaryStateId = item.stateId;
        }
      });
    },

    changeStatus(val, item) {
      item.changeStatus = true;
      this.axios
        .post(cv.changeStatus, {
          rowId: item.id,
          stateId: val,
          fullname: item.fullname,
        })
        .then(() => {
          this.getAll();
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          item.changeStatus = false;
        });
    },

    filterButton(val) {
      this.currentPage = 0;
      this.blackList = "";
      this.stateId = "";
      if (val.id === 2) {
        this.$refs.filtering.toggleSidebar();
      } else if (val.id === 4) {
        this.blackList = 4;
        this.getAll();
      } else if (val.id === 1) {
        this.stateId = 3;
        this.getAll();
      } else {
        this.blackList = "";
        this.stateId = "";
        this.getAll();
      }
      this.projectState.forEach((item) => {
        item.active = item.id === val.id;
      });
      this.projectFilter = false;
    },
    toggleDown() {
      if (this.$refs.filtering.isOpen == true) {
        this.$refs.filtering.toggleSidebar();
      }
      this.projectFilter = false;
    },
    cvFavorite(item, index) {
      if (item.stateId != 4) {
        item.isFavorite == 2
          ? (this.List[index].isFavorite = 1)
          : (this.List[index].isFavorite = 2);
        this.axios
          .post(cv.isFavorite, {
            rowId: item.id,
            stateId: item.isFavorite,
            fullname: item.fullname,
          })
          .then(() => {})
          .catch((err) => {
            this.authController(err);
          });
      }
    },
    getData() {
      if (this.$store.state.resumeFilter.length > 0) {
        setTimeout(() => {
          this.$refs.filtering.applyFilters();
        }, 50);
      } else {
        this.getAll();
      }
    },
  },
  // mounted() {
  //   this.getAll();
  // },
  created() {
    // if (this.$store.state.resumeFilter.length == 0) {
    this.getAll();
    // }
  },
  filters: {
    getDate(val) {
      if (val) return moment(val).format("LL");
      else return this.$t("cv.index.date");
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
