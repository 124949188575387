<template>
  <modal
    name="editInterview"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '40%'"
    :scrollable="true"
    class="scroltype"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-xl">
        {{
          $t(
            "project.projectUser.components.interview.components.edit.addQuestion"
          )
        }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <form
      @submit.prevent="save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
    >
      <div class="space-y-4 pb-3">
        <div class="flex gap-10 max-sm:flex-wrap max-sm:gap-3 w-ull">
          <div class="w-full relative">
            <!-- Soru -->
            <customInput
              v-model="question"
              type="textarea"
              textareaClass="min-h-32 max-h-32"
              min="5"
              max="2500"
              :required="true"
              :placeholder="
                $t(
                  'project.projectUser.components.interview.components.edit.placeholderAddQuestion'
                )
              "
              :title="
                $t(
                  'project.projectUser.components.interview.components.edit.question'
                )
              "
            />
          </div>
        </div>

        <!-- Karar Sorusu -->
        <div class="flex w-full gap-5">
          <customInput
            v-model="questionState"
            type="select"
            :title="
              $t(
                'project.projectUser.components.interview.components.edit.questionType'
              )
            "
            :selectList="[
              {
                value: 1,
                name: $t(
                  'project.projectUser.components.interview.components.edit.question'
                ),
              },
              {
                value: 2,
                name: $t(
                  'project.projectUser.components.interview.components.edit.decisionQuestion'
                ),
              },
            ]"
            :required="true"
          />
        </div>

        <div v-if="questionState == 2">
          <div class="flex w-full gap-5">
            <customInput
              v-model="expectedAnswer"
              type="select"
              :title="
                $t(
                  'project.projectUser.components.interview.components.edit.decisionQuestionAnswer'
                )
              "
              :selectList="[
                {
                  value: 1,
                  name: $t(
                    'project.projectUser.components.interview.components.edit.yes'
                  ),
                },
                {
                  value: 2,
                  name: $t(
                    'project.projectUser.components.interview.components.edit.no'
                  ),
                },
              ]"
              :required="true"
            />
          </div>
        </div>

        <div class="flex w-full gap-5">
          <customInput
            v-model="isEditQuestion"
            type="select"
            :title="
              $t(
                'project.projectUser.components.interview.components.edit.shouldChange'
              )
            "
            :selectList="[
              {
                value: 1,
                name: $t(
                  'project.projectUser.components.interview.components.edit.yes'
                ),
              },
              {
                value: 2,
                name: $t(
                  'project.projectUser.components.interview.components.edit.no'
                ),
              },
            ]"
            :required="true"
          />
        </div>
        <div class="w-full relative">
          <!-- Soru -->
          <customInput
            v-model="weightedScore"
            type="number"
            min="0"
            max="100"
            :required="true"
            placeholder="Değerlendirme Puanı  "
            title=" Değerlendirme Puanı"
          />
        </div>
        <!-- <div class="flex w-full gap-5">
          <customInput v-model="answerQuality" type="select" title="
        Cecap Türü
            " :selectList="[
              {
                value: 1,
                name: 'Soyut'
              },
              {
                value: 2,
                name: 'Somut'
              },
            ]" :required="true" />
        </div> -->
        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="
                $t(
                  'project.projectUser.components.interview.components.edit.save'
                )
              "
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import { projectInterview } from "@/networking/urlmanager";
import requiredField from "@/components/requiredField.vue";

export default {
  name: "editProject",
  props: ["detail"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      question: "",
      load: false,
      questionState: 1,
      rowId: "",
      expectedAnswer: 1,
      isEditQuestion: 1,
      weightedScore: 0,
      answerQuality: 1,
    };
  },
  methods: {
    show() {
      setTimeout(() => {
        this.question = this.detail.question;
        this.questionState = this.detail.questionState;
        this.rowId = this.detail.id;
        this.isEditQuestion = this.detail.isEditQuestion;
        this.expectedAnswer = this.detail.expectedAnswer;
        this.weightedScore = this.detail.weightedScore;
        this.answerQuality = this.detail.answerQuality;
      }, 50);
      this.$modal.show("editInterview");
    },
    hide() {
      this.$modal.hide("editInterview");
    },
    save() {
      this.load = true;
      this.axios
        .post(projectInterview.edit, {
          rowId: this.rowId,
          question: this.question,
          questionState: this.questionState,
          isEditQuestion: this.isEditQuestion,
          expectedAnswer: this.expectedAnswer,
          projectId: this.$route.query.id,
          weightedScore: this.weightedScore,
          answerQuality: this.answerQuality,
        })
        .then((res) => {
          this.successMessage(res.data.message);
          this.load = false;
          this.question = "";
          (this.questionState = 1), this.$emit("refresh", true);
          this.hide();
        })
        .catch((err) => {
          this.load = false;
          this.authController(err);
        });
    },
  },
  mount() {
    this.show();
  },
};
</script>
