var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interview-method"},[(_vm.isOnline)?_c('div',[(_vm.isOnline)?_c('meet',{ref:"meetRef",attrs:{"detail":_vm.selectedAddress}}):_vm._e()],1):_c('div',{staticClass:"min-h-[300px]"},[(_vm.isLoadingAddress)?_c('tableLoader',{staticClass:"min-h-[300px]",attrs:{"load":_vm.isLoadingAddress,"colspan":"7"}}):_c('div',{staticClass:"space-y-4"},[(_vm.addressDetails.adress)?_c('div',{staticClass:"contact-detail"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewMethod.address" ))+" ")]),_c('p',[_vm._v(_vm._s(_vm.addressDetails.adress))])]):_vm._e(),(_vm.addressDetails.phone)?_c('div',{staticClass:"contact-detail"},[_c('strong',[_vm._v(_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewMethod.phone" )))]),_c('p',[_vm._v(_vm._s(_vm.addressDetails.phone))])]):_vm._e(),(_vm.addressDetails.email)?_c('div',{staticClass:"contact-detail"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewMethod.email" ))+" ")]),_c('p',[_vm._v(_vm._s(_vm.addressDetails.email))])]):_vm._e(),(_vm.addressDetails.website)?_c('div',{staticClass:"contact-detail"},[_c('strong',[_vm._v(_vm._s(_vm.$t( "project.projectUser.components.shortList.components.steps.interviewMethod.webSite" )))]),_c('p',[_vm._v(_vm._s(_vm.addressDetails.website))])]):_vm._e(),_c('async-button',{attrs:{"load":_vm.isGeneratingAddressMeeting,"text":_vm.isGeneratingAddressMeeting
    ? _vm.$t(
      'project.projectUser.components.shortList.components.steps.interviewMethod.sendAddressing'
    )
    : _vm.$t(
        'project.projectUser.components.shortList.components.steps.interviewMethod.sendAddress'
      ),"buttonClass":"w-full"},on:{"click":_vm.generateAddressMeetingInvitation}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }