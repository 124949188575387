<template>
  <div>
    <form @submit.prevent="save" novalidate>
      <navbar
        :load="getLoad"
        :buttonLoad="load"
        :title="$t('settings.contact.index.contactTitle')"
        :description="$t('settings.contact.index.contactText')"
      />

      <div
        v-if="!getLoad"
        class="mt-3 mb-5 w-full max-md:mt-0 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"
      >
        <settingInput
          :title="$t('settings.contact.index.companyAddress')"
          v-model="form.adress"
          :required="true"
          type="text"
          min="2"
          max="250"
          :description="$t('settings.contact.index.componyText')"
        />

        <settingInput
          type="phoneNumber"
          :title="$t('settings.contact.index.phone')"
          v-model="form.phone"
          :description="$t('settings.contact.index.phoneText')"
          @change="handlePhoneNumberChange"
        />

        <settingInput
          :title="$t('settings.contact.index.email')"
          v-model="form.email"
          :required="true"
          type="email"
          min="2"
          max="255"
          :description="$t('settings.contact.index.emailText')"
        />

        <settingInput
          :title="$t('settings.contact.index.website')"
          v-model="form.website"
          :required="false"
          type="text"
          min="2"
          max="100"
          :description="$t('settings.contact.index.websiteText')"
        />

        <div class="flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.contact.index.socialMedia") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.contact.index.socialMediaText") }}
            </p>
          </div>
          <div class="w-6/12 h-full grid grid-cols-1 gap-4 max-md:w-full">
            <Inputs
              :title="$t('settings.contact.index.linkedIn')"
              v-model="form.linkedin"
              :required="false"
              type="text"
              min="2"
              max="100"
            />
            <Inputs
              :title="$t('settings.contact.index.ınstagram')"
              v-model="form.instagram"
              :required="false"
              type="text"
              min="2"
              max="100"
            />
            <Inputs
              :title="$t('settings.contact.index.twitter')"
              v-model="form.twitter"
              :required="false"
              type="text"
              min="2"
              max="100"
            />
          </div>
        </div>

        <div class="flex gap-5 py-8 border-b border-gray-200 max-md:flex-wrap">
          <div class="w-6/12 max-md:w-full">
            <h1 class="text-base text-gray-700 font-semibold">
              {{ $t("settings.contact.index.communication") }}
            </h1>
            <p class="text-xs text-gray-700 mt-2">
              {{ $t("settings.contact.index.socialMediaText") }}
            </p>
            <button
              type="button"
              class="text-base block mt-4 bgcol px-8 py-2 rounded text-white"
              @click="$refs.addContact.show()"
            >
              {{ $t("settings.contact.index.new") }}
            </button>
          </div>
          <div class="w-6/12 h-full grid grid-cols-1 gap-4 max-md:w-full">
            <div
              v-for="(item, index) in ListPersonal"
              :key="index"
              class="border-b border-gray-100 w-full flex gap-3 pb-4"
            >
              <div class="w-10/12">
                <h2 class="text-base text-gray-800 font-semibold">
                  {{ item.name }}
                </h2>
                <p class="text-sm text-gray-700">{{ item.email }}</p>
                <p class="text-sm text-gray-700">{{ item.phone }}</p>
              </div>
              <div class="w-2/12 flex text-sm">
                <i
                  class="fa-solid fa-pen-to-square text-base cursor-pointer ml-8 max-sm:ml-0"
                  @click="
                    () => ((selectedItem = item), $refs.editContact.show())
                  "
                >
                </i>
                <i
                  class="fa-solid fa-trash-can text-base ml-4 cursor-pointer"
                  @click="deleteItem(item)"
                ></i>
              </div>
            </div>

            <div
              v-if="ListPersonal.length == 0"
              class="text-center text-sm mt-10 text-gray-700"
            >
              {{ $t("settings.contact.index.advisorNotFound") }}
            </div>
          </div>
        </div>
      </div>
    </form>

    <add ref="addContact" @refresh="getData('personal')" />
    <edit
      ref="editContact"
      :detail="selectedItem"
      @refresh="getData('personal')"
    />
  </div>
</template>

<script>
import navbar from "../components/navbar.vue";
import settingInput from "../components/settingInput.vue";
import Inputs from "@/components/customInput.vue";
import add from "./components/add.vue";
import edit from "./components/edit.vue";
import _ from "lodash";

import {
  settingContact,
  settingContactPersonal,
} from "@/networking/urlmanager";

export default {
  name: "settingContactPage",
  components: {
    navbar,
    settingInput,
    add,
    edit,
    Inputs,
  },
  data() {
    return {
      form: {
        adress: "",
        phone: "",
        email: "",
        website: "",
        linkedin: "",
        instagram: "",
        twitter: "",
      },
      getLoad: false,
      load: false,
      initialValues: [],
      List: [],
      ListPersonal: [],
      selectedItem: {
        id: "",
        name: "",
        email: "",
        phone: "",
      },
      phoneIsValid: false,
    };
  },
  methods: {
    onFileSelectedLogo(val) {
      this.logo = val;
    },
    onFileSelectedFormat(val) {
      this.format = val;
    },
    async getData(type) {
      if (type === "all") {
        this.getLoad = true;
        this.List = [];
        try {
          const res = await this.axios.get(settingContact.getAll, {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          });

          this.List = res.data?.data[0];
          this.form.adress = res.data.data[0]?.adress;
          this.form.phone = res.data.data[0]?.phone;
          this.form.email = res.data.data[0]?.email;
          this.form.website = res.data.data[0]?.website;
          this.form.linkedin = res.data.data[0]?.linkedin;
          this.form.instagram = res.data.data[0]?.instagram;
          this.form.twitter = res.data.data[0]?.twitter;
        } catch (err) {
          console.log(err);
          this.authController(err);
        } finally {
          this.getLoad = false;
          this.initialValues = _.cloneDeep(this.form);
        }
      } else if (type === "personal") {
        this.load = true;
        this.List = [];
        try {
          const res = await this.axios.get(settingContactPersonal.getAll, {
            headers: {
              Authorization: "Bareer " + this.$store.state.userData.token,
            },
          });
          this.ListPersonal = res.data.data;
          this.initialValues = _.cloneDeep(this.form);
        } catch (err) {
          console.log(err);
          this.authController(err);
        } finally {
          this.load = false;
        }
      }
    },
    save() {
      const formFields = [
        { field: this.form.instagram, name: "instagram" },
        { field: this.form.twitter, name: "twitter" },
        { field: this.form.linkedin, name: "linkedin" },
        // { field: this.form.website, name: "website" },
      ];

      for (let i = 0; i < formFields.length; i++) {
        if (!this.isValidUrl(formFields[i].field, formFields[i].name)) {
          this.warningMesage(
            this.$t(
              `settings.contact.index.invalid${
                formFields[i].name.charAt(0).toUpperCase() +
                formFields[i].name.slice(1)
              }`
            )
          );
          return;
        }
      }

      if (this.phoneIsValid) {
        this.warningMesage(this.$t("settings.contact.index.invalidPhone"));
        return;
      }

      if (!this.isValidEmail(this.form.email)) {
        this.warningMesage(this.$t("settings.contact.index.invalidEmail"));
        return;
      }

      if (Object.values(this.form).some((value) => value)) {
        this.load = true;
        this.axios
          .post(settingContact.edit, this.form, {
            headers: {
              Authorization: "Bearer " + this.$store.state.userData.token,
            },
          })
          .then((res) => {
            this.successMessage(res.data.message);
            this.$store.commit("setContactCompany", null);
            this.getData("all");
            this.load = false;
          })
          .catch((err) => {
            this.load = false;
            this.authController(err);
          });
      }
    },
    deleteItem(item) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              settingContactPersonal.delete,
              {
                rowId: item.id,
              },
              {
                headers: {
                  Authorization: "Bareer " + this.$store.state.userData.token,
                },
              }
            )
            .then((res) => {
              this.successMessage(res.data.message);
              this.getData("personal");
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    },
    isValidUrl(url, platform) {
      let pattern;
      switch (platform) {
        case "instagram":
          pattern = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_\.]+/;
          break;
        case "twitter":
          pattern =
            /^(https?:\/\/)?((www\.)?twitter\.com\/[a-zA-Z0-9_]+|(www\.)?x\.com\/[a-zA-Z0-9_]+)/;
          break;
        case "linkedin":
          pattern =
            /^(https?:\/\/)?(www\.)?linkedin\.com\/(in\/)?[a-zA-Z0-9_-]+/;
          break;
        case "website":
          pattern =
            /^(https?:\/\/)?(www\.)?([a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)$/;
          break;
        default:
          return false;
      }
      return pattern.test(url);
    },

    isValidEmail(email) {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return pattern.test(email);
    },

    handlePhoneNumberChange(value) {
      this.form.phone = value;
      if (!value) {
        this.phoneIsValid = true;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.getLoad && !_.isEqual(this.form, this.initialValues)) {
      this.verifiedMessage(
        this.$t("settings.general.index.leaveMessage"),
        this.$t("settings.general.index.leaveMessageYes"),
        this.$t("settings.general.index.leaveMessageNo")
      ).then((result) => {
        if (result.isConfirmed) {
          next(true);
        } else {
          next(false);
        }
      });
    } else {
      next(true);
    }
  },
  async created() {
    await this.getData("all");
    await this.getData("personal");
  },
};
</script>
