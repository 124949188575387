<template>
  <form class="class-name space-y-5 p-5" @submit.prevent="submitForm">
    <div
      v-if="types.length === 0"
      class="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4"
    >
      <p class="text-yellow-700">
        <i class="fa-solid fa-exclamation-triangle mr-2"></i>
        {{
          $t(
            "project.projectUser.components.shortList.components.steps.interviewType.noInterviewType"
          )
        }}
      </p>
      <router-link
        to="/setting"
        class="mt-2 inline-block bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600 transition"
      >
        {{
          $t(
            "project.projectUser.components.shortList.components.steps.interviewType.goToSettings"
          )
        }}
      </router-link>
    </div>
    <template v-else>
      <customInput
        v-model="InterviewType.name"
        type="text"
        :min="3"
        :max="40"
        :required="true"
        :placeholder="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.interviewTitle'
          )
        "
        :title="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.interviewTitle'
          )
        "
      />
      <customInput
        v-model="InterviewType.type"
        type="select"
        :selectList="types"
        :required="true"
        :placeholder="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.interviewType'
          )
        "
        :title="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.interviewType'
          )
        "
      />
      <customInput
        v-model="InterviewType.isOnline"
        type="select"
        :selectList="[
          {
            value: 2,
            name: $t(
              'project.projectUser.components.shortList.components.steps.interviewType.faceToface'
            ),
          },
          {
            value: 1,
            name: $t(
              'project.projectUser.components.shortList.components.steps.interviewType.online'
            ),
          },
        ]"
        :required="true"
        :placeholder="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.interviewPlace'
          )
        "
        :title="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.interviewPlace'
          )
        "
      />
      <div v-if="InterviewType.isOnline == 1" class="w-full">
        <label class="textcol font-semibold flex gap-1 items-center">
          {{
            $t(
              "project.projectUser.components.shortList.components.steps.interviewType.addAttendee"
            )
          }}
        </label>
        <userAddSelect @emails-selected="handleSelectedEmails" />
      </div>
      <asyncButton
        :text="
          $t(
            'project.projectUser.components.shortList.components.steps.interviewType.next'
          )
        "
        class="w-full"
        type="submit"
        :disabled="types.length === 0"
      />
    </template>
  </form>
</template>

<script>
import asyncButton from "@/components/button.vue";
import customInput from "@/components/customInput.vue";
import userAddSelect from "@/components/dev/userAddSelect.vue";
import { settingGeneral } from "@/networking/urlmanager";

export default {
  name: "InterviewTypeForm",
  components: {
    customInput,
    asyncButton,
    userAddSelect,
  },
  props: ["types"],
  data() {
    return {
      InterviewType: {
        name: "",
        type: "",
        isOnline: null,
        selectedEmails: [],
      },
    };
  },
  methods: {
    handleSelectedEmails(emails) {
      this.InterviewType.selectedEmails = emails;
    },
    validateForm() {
      const { name, type, isOnline } = this.InterviewType;
      if (!name.trim() || !type || isOnline === null) {
        this.$toast.error(
          this.$t(
            "project.projectUser.components.shortList.components.steps.interviewType.pleaseFillInAllFields"
          ),
          {
            position: "top-right",
          }
        );
        return false;
      }
      return true;
    },

    submitForm() {
      if (this.types.length === 0) {
        this.$toast.warning(
          this.$t(
            "project.projectUser.components.shortList.components.steps.interviewType.addInterviewType"
          ),
          {
            position: "top-right",
          }
        );
        return;
      }
      if (this.validateForm()) {
        const payload = {
          ...this.InterviewType,
          type: this.InterviewType.type,
          selectedEmails: this.InterviewType.selectedEmails, // Seçilen e-postalar buraya ekleniyor
        };
        this.$emit("submit", payload); // Alt bileşene gönderilen payload'ı kontrol edin
      }
    },
  },
};
</script>
