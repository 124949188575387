<template>
  <div>
    <slot name="external" />
    <transition name="fade">
      <div
        v-show="isOpen"
        @mousedown="handleBackdropClick"
        class="fixed inset-0 z-[998] bg-black transition-all duration-300"
        :class="['bg-opacity-0', isOpen ? '!bg-opacity-20' : '', externalClass]"
      >
        <transition name="slide">
          <div
            v-show="isOpen"
            @mousedown.stop
            class="fixed top-0 right-0 h-screen z-[998] bg-white shadow-md flex flex-col"
            :class="modalClass"
          >
            <header 
              v-if="isHeader" 
              class="flex justify-between items-center p-4 border-b border-gray-200"
            >
              <button @click="close" class="text-[#344CA0]">
                <i class="fas fa-times text-2xl" />
              </button>
              <h2 class="text-lg font-bold text-[#344CA0]">{{ headerTitle }}</h2>
              <button 
                v-if="isRefresh || $slots.headerRight"
                @click="$emit('refresh', true)"
              >
                <i v-if="isRefresh" class="fa-solid fa-arrows-rotate text-[#344CA0] text-xl" />
                <slot v-else name="headerRight" />
              </button>
            </header>
 
            <div 
              class="flex-1 custom-scrollbar overflow-hidden px-4"
              :class="{ 'overflow-y-auto': !hidenOverflowY }"
            >
              <slot name="content" />
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
 </template>
 
 <script>
 export default {
  name: "CustomModal",
  props: {
    externalClass: String,
    modalClass: String,
    isHeader: Boolean,
    headerTitle: String,
    isClosable: Boolean,
    closeController: Boolean,
    isRefresh: Boolean,
    hidenOverflowY: Boolean,
    headerRight: Boolean,
    length: [Number, String]
  },
  
  data: () => ({
    isOpen: false
  }),
 
  methods: {
    show() {
      this.isOpen = true;
      this.$emit("show", true);
      document.body.style.overflow = 'hidden';
    },
    
    close() {
      if (this.isClosable && !this.closeController) {
        this.$emit("closeError", true);
        return;
      }
      this.isOpen = false;
      document.body.style.overflow = '';
    },
 
    handleBackdropClick() {
      if (!this.isClosable) {
        this.close();
      }
    }
  },
 
  beforeDestroy() {
    document.body.style.overflow = '';
  }
 };
 </script>
 
 <style scoped>
 .fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
 }
 .fade-enter, .fade-leave-to {
  opacity: 0;
 }
 
 .slide-enter-active, .slide-leave-active {
  transition: transform 0.3s;
 }
 .slide-enter, .slide-leave-to {
  transform: translateX(100%);
 }
 
 .custom-scrollbar::-webkit-scrollbar {
  width: 6px;
 }
 
 .custom-scrollbar::-webkit-scrollbar-thumb {
  background: #344CA0;
  border-radius: 0.5rem;
 }
 </style>