<template>
    <div class="language-select-wrapper w-full">
      <multiselect
        :options="filterList"
        :value="getList()"
        @select="onSelect"
        @remove="onRemove"
        :multiple="!singleSelect"
        :close-on-select="singleSelect"
        :clear-on-select="false"
        :preserve-search="true"
        placeholder=""
        label="name"
        track-by="id"
        :selectLabel="$t('globalComponents.dev.languageSelect.add')"
        :deselectLabel="$t('globalComponents.dev.languageSelect.delete')"
        :loading="load"
        :internal-search="false"
        @search-change="asyncFind"
        class="custom-multiselect"
      >
        <template v-slot:selection="{ values }">
          <template v-if="!singleSelect">
            {{ values.length }}
            {{ $t("globalComponents.dev.languageSelect.languageChosen") }}
          </template>
          <template v-else>
            {{ values[0]?.name || '' }}
          </template>
        </template>
      </multiselect>
    </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utility } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: {
    value: {
      type: [Array, Object],
      default: () => []
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    singleSelect: {
      type: Boolean,
      default: false
    }
  },
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      List: [],
      filterList: [],
      load: false,
      fuse: null,
      allowedIds: [2, 14] // Define allowed IDs
    };
  },
  methods: {
    onSelect(val) {
      if (this.singleSelect) {
        const selectedItem = {
          id: val.id,
          name: val.name,
          status: 2
        };
        this.selectedList = [selectedItem];
        this.$emit("change", selectedItem);
        return;
      }

      let findIndex = -1;
      if (Array.isArray(this.selectedList)) {
        if (this.selectedList.length > 0) {
          findIndex = this.selectedList.findIndex((item) => item.id == val.id);
        }
      }

      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (this.isEdit && item.status == 3 && item.rowId)
          this.selectedList[findIndex].status = 1;
        if (item.status != 1) this.selectedList[findIndex].status = 2;
      } else {
        const tag = {
          id: val.id,
          name: val.name,
          status: 2,
        };
        this.selectedList.push(tag);
      }
      
      this.$emit("change", this.selectedList);
    },
    onRemove(val) {
      const findIndex = this.selectedList.findIndex((r) => r.id == val.id);
      if (this.isEdit) {
        this.selectedList[findIndex].status = 3;
      } else {
        this.selectedList.splice(findIndex, 1);
      }
      
      if (this.singleSelect) {
        this.$emit("change", {});
      } else {
        this.$emit("change", this.selectedList);
      }
    },
    getList() {
      return this.selectedList.filter((r) => r.status != 3);
    },
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = this.List.filter(item => this.allowedIds.includes(item.id));
        return;
      }

      const results = this.fuse.search(val);
      const newFilterList = results
        .map((result) => result.item)
        .filter(item => this.allowedIds.includes(item.id));
      this.filterList = newFilterList;
    },
    async getAll() {
      try {
        this.load = true;
        const response = await this.axios.get(utility.getLanguagesAll);
        // Filter languages to only include those with allowed IDs
        this.List = response.data.data.filter(item => this.allowedIds.includes(item.id));
        this.filterList = [...this.List];
        this.load = false;

        this.fuse = new Fuse(this.List, {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        });
      } catch (error) {
        this.load = false;
        console.error("Dil bilgileri yüklenirken bir hata oluştu:", error);
      }
    },
  },
  async created() {
    await this.getAll();
    if (this.value && (Array.isArray(this.value) ? this.value.length > 0 : Object.keys(this.value).length > 0)) {
      if (this.singleSelect && typeof this.value === 'object' && !Array.isArray(this.value)) {
        this.selectedList = this.value.id ? [this.value] : [];
      } else {
        // Filter initial value to only include allowed IDs
        this.selectedList = Array.isArray(this.value) 
          ? this.value.filter(item => this.allowedIds.includes(item.id))
          : this.value;
      }
    }
  },
  watch: {
    selectedList(val) {
      this.filterList.sort((a, b) => {
        const indexA = this.selectedList.findIndex((r) => r.id === a.id);
        const indexB = this.selectedList.findIndex((r) => r.id === b.id);
        return indexB - indexA;
      });
    },
    value(val) {
      if (val && (Array.isArray(val) ? val.length > 0 : Object.keys(val).length > 0)) {
        if (this.singleSelect && typeof val === 'object' && !Array.isArray(val)) {
          this.selectedList = val.id ? [val] : [];
        } else {
          // Filter new value to only include allowed IDs
          this.selectedList = Array.isArray(val)
            ? val.filter(item => this.allowedIds.includes(item.id))
            : val;
        }
      } else {
        this.selectedList = [];
      }
    },
  },
};
</script>