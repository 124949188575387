<template>
  <div
    v-if="!load && List.length > 0"
    class="mt-2 overflow-y-auto"
    style="height: calc(100% - 110px)"
  >
    <div
      v-for="(item, index) in List"
      :key="index"
      class="flex justify-between items-center py-3.5 px-4 bg-white border-b border-gray-100 hover:bg-gray-50 transition-colors"
    >
      <span class="text-[15px] text-gray-900">{{ formatText(item.fileName) }}</span>
      
      <div class="flex items-center gap-4">
        <!-- Email/Hata Durumu -->
        <button
          v-if="isCvId(item)"
          @click="$emit('addEmail', item)"
          class="text-blue-600 hover:text-blue-700 text-sm font-medium animate-pulse"
        >
          {{ $t("project.detail.table.addEmail") }}
        </button>
        <span
          v-else
          class="text-red-500 text-sm font-medium"
        >
          {{ $t("cv.components.cvListModal.tab.error") }}
        </span>

        <!-- Silme Butonu -->
        <button 
          @click="deleteItem(item)"
          class="text-gray-400 hover:text-red-500 transition-colors p-1 rounded-full hover:bg-red-50"
        >
        <i class="fa-solid fa-trash-can text-[14px]"></i>
      </button>
      </div>
    </div>
  </div>
</template>

<script>
import { cv } from "@/networking/urlmanager";

export default {
  name: "loading-cv",
  props: ["load"],
  data() {
    return {
      List: [],
    };
  },
  methods: {
    isCvId(item) {
      return Boolean(item.cvId);
    },
    async getAll(loading = true) {
      try {
        if(this.load)return;

        this.$emit("load", loading);
        const res = await this.axios.get(cv.getCvTrain, {
          params: {
            stateId: 3,
          },
        });
        
        this.List = res.data.data.map((r) => ({
          id: r.id,
          data: this.listFormatController(r.completion),
          originalFile: r.originalFile,
          stateId: r.stateId,
          fileName: r.fileName,
          isView: 1,
          cvId: r.cvId,
        }));

        this.$emit("chanceList", this.List);
        this.$store.commit("extractCvList", this.List);
        this.$emit("load", false);
      } catch (err) {
        console.log(err);
        this.authController(err);
        this.$emit("load", false);
      }
    },
    async deleteItem(item) {
      try {
        const data = await this.verifiedMessage();
        if (data.isConfirmed) {
          await this.axios.post(cv.deleteTrain, {
            rowId: item.id,
          });
          await this.getAll();
        }
      } catch (err) {
        console.log(err);
        this.authController(err);
      }
    },
  },
};
</script>