import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import profilePage from "../views/profile/index.vue";

import cvPage from "../views/cv/index.vue";
import cvProfilePage from "../views/cv/profile/index.vue";
import saveMiddlePage from "../views/cv/cvSaveMiddle.vue";
import cvSimilarPage from "../views/cvSimilar/index.vue";

import generalPage from "../views/general/index.vue";

import meetingPage from "../views/meeting/meetingConnect/index.vue";
import meetingLivePage from "../views/meeting/meetingLive/index.vue";

import projectPage from "../views/project/index.vue";
import projectUserPage from "../views/project/projectUser/index.vue";

import supportPage from "../views/support/index.vue";
import supportDetailPage from "../views/support/detail/index.vue";

//import companyPage from '../views/company/index.vue';

import NotFoundPage from "../views/error/index.vue";
import usersPage from "../views/users/index.vue";

//settings
import settingPage from "../views/settings/general/index.vue";
import contactPage from "../views/settings/contact/index.vue";
import settingConfigrationPage from "../views/settings/configration/index.vue";

// auth
import signInPage from "../views/auth/signIn.vue";
import signUpPage from "../views/auth/signUp.vue";
import setPasswordPage from "../views/auth/setPassword.vue";
import confirmPasswordPage from "../views/auth/confirmPassword.vue";

// outlook
import outlookPage from "../views/oauth/outlookConnection/index";

//meet connection

import meetPage from "../views/oauth/meetConnection/index.vue";

//privacy policy

import privacyPolicy from "../views/privacy-policy/index.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "signIn",
    component: signInPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/sign/up",
    name: "signUp",
    component: signUpPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/set/password",
    name: "setPassword",
    component: setPasswordPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/confirm/password",
    name: "confirmPassword",
    component: confirmPasswordPage,
    meta: { requiresAuth: false },
  },
  {
    path: "/profile",
    name: "profile",
    component: profilePage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/cv/:currentPage",
    name: "cv",
    component: cvPage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/cv/profile/:id",
    name: "cvProfile",
    component: cvProfilePage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/cv/save/middleware",
    name: "cvSaveMiddleware",
    component: saveMiddlePage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/cv/similar",
    name: "cvSimilarPage",
    component: cvSimilarPage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/general",
    name: "general",
    component: generalPage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/meeting",
    name: "meeting",
    component: meetingPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/meeting/live",
    name: "meetingLive",
    component: meetingLivePage,
    meta: { requiresAuth: true },
  },
  {
    path: "/project/:currentPage",
    name: "project",
    component: projectPage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/project/:currentPage/user",
    name: "projectUser",
    component: projectUserPage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/users",
    name: "users",
    component: usersPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/support",
    name: "support",
    component: supportPage,
    meta: { requiresAuth: true, user: true },
  },
  {
    path: "/support/:id",
    name: "supportDetail",
    component: supportDetailPage,
    meta: { requiresAuth: true, user: true },
  },
  //settings
  {
    path: "/setting",
    name: "setting",
    component: settingPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting/contact",
    name: "contact",
    component: contactPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting/configration",
    name: "configration",
    component: settingConfigrationPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/outlook",
    name: "outlook",
    component: outlookPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/meet/connection",
    name: "meetConnection",
    component: meetPage,
    meta: { requiresAuth: true },
  },

  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: privacyPolicy,
  },

  // {
  //   path: '/company',
  //   name: 'company',
  //   component: companyPage,
  //   meta: { requiresAuth: true }
  // },
  {
    path: "*",
    name: "notFound",
    component: NotFoundPage,
    meta: { requiresAuth: true, user: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.userData.isAuth) {
    next("/");
  } else {
    if (
      store.state.userData.authority == 1 ||
      store.state.userData.authority == 3
    ) {
      next();
    } else if (!to.matched.some((record) => record.meta.requiresAuth)) {
      next();
    } else {
      if (to.matched.some((record) => record.meta.user)) {
        next();
      } else {
        next("/");
      }
    }
  }
});

export default router;
