<template>
  <div
    class="relative group flex justify-center items-center"
    :class="containerClass"
  >
    <button
      :disabled="isDisabled"
      :type="buttonType"
      @click="handleClick"
      :readonly="readonly"
      :class="[buttonClasses, !readonly ? { '!bg-gray-400': isDisabled } : ' ']"
    >
      <img
        v-if="shouldShowImage"
        :src="imgSrc"
        :class="[imgClass || 'w-4 mr-3']"
        :alt="alt"
      />
      <div
        :class="buttonBodyClass"
        class="flex justify-center items-center gap-2"
      >
        <i v-if="awesome" :class="awesome"></i>
        <div v-if="!hiddenText">
          <span v-if="!load" class="text-center"> {{ buttonText }}</span>
          <span v-else class="flex text-xs gap-1.5 justify-center items-center">
            <div :class="spinnerClasses"></div>
            <p>{{ loadText || $t("button.loading") }}</p>
          </span>
        </div>
      </div>
    </button>

    <tooltip
      v-if="isTooltip"
      class="mt-2"
      :title="toolTipTitle"
      :tipClass="toolTipClass"
      :top="tooltipTop"
      :bottom="tooltipBottom"
      :rotateClass="tooltipRotateClass"
    />
  </div>
</template>

<script>
import tooltip from "@/components/tooltip.vue";

export default {
  name: "AsyncButton",
  props: [
    "buttonBodyClass",
    "hiddenText",
    "containerBackground",
    "type",
    "imgHidden",
    "imgSrc",
    "text",
    "loadText",
    "load",
    "buttonClass",
    "containerClass",
    "disabled",
    "readonly",
    "imgClass",
    "spinnerClass",
    "alt",
    "toolTipTitle",
    "toolTipClass",
    "tooltipTop",
    "tooltipBottom",
    "tooltipRotateClass",
    "isTooltip",
    "awesome",
  ],
  components: {
    tooltip,
  },
  computed: {
    isDisabled() {
      return this.load || this.disabled;
    },
    buttonType() {
      return this.type || "submit";
    },
    shouldShowImage() {
      return !this.imgHidden && this.imgSrc && !this.load;
    },
    buttonText() {
      return this.load
        ? this.loadText || this.$t("button.loading")
        : this.text || this.$t("button.name");
    },
    spinnerClasses() {
      return [
        this.spinnerClass ? this.spinnerClass : "border-white",
        "w-4 h-4 rounded-full animate-spin border-2 border-solid border-t-transparent shadow-md",
      ];
    },
    buttonClasses() {
      return [
        "px-4 py-2 h-11 w-full text-[15px] rounded max-md:text-sm",
        this.buttonClass,
        this.containerBackground ? this.containerBackground : "bgcol",
        "text-white",
        { "flex items-center gap-2": this.shouldShowImage },
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit("click", true);
    },
  },
};
</script>
