<template>
  <div class="relative inline-block text-left" ref="dropwdown">
    <div>
      <i
        class="fas fa-language cursor-pointer text-gray-100 text-xl"
        @click.stop="showDropdown = !showDropdown"
        ref="button"
      ></i>
    </div>

    <div
      v-show="showDropdown"
      class="origin-top-right absolute right-0 mt-2 w-24 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
      @click.stop
    >
      <div
        class="py-1"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
      <div v-if="load" class="px-4 py-2 text-lg text-gray-700 flex gap-1 items-center justify-center">
        <i class="fas fa-spinner fa-spin"></i> 
      </div>
      <div v-else>
        <button
          class="block px-4 py-2  w-full text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          @click="changeLanguage('tr')"
          :disabled="active === 'tr'"
        >
          <span :class="{ 'text-blue-700': active === 'tr' }"> Türkçe </span>
        </button>
        <button
          class="block px-4 w-full py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          role="menuitem"
          @click="changeLanguage('en')"
          :disabled="active === 'en'"
        >
          <span :class="{ 'text-blue-700': active === 'en' }"> English </span>
        </button>
      </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/networking/urlmanager";
import moment from 'moment'

export default {
  name: "globalLanguageSelect",
  data() {
    return {
      showDropdown: false,
      active: this.$store.state.userData.locale,
      button: null,
      dropwdown: null,
      load:false
    };
  },
  computed: {
    activeLocale() {
      return this.$store.state.userData.locale;
    },
  },
  methods: {
    async changeLanguage(lang) {
      try {
        this.load = true
        this.axios.defaults.headers['Accept-Language'] = lang;
        await this.axios.post(auth.setLanguage, {
          language: lang,
        });
        this.$i18n.locale = lang;
        moment.locale(lang)
        this.showDropdown = false;
        this.active = lang;
        this.$store.commit("setLocale", lang);
        this.load = false
      } catch (err) {
        console.log(err)
        this.load = false
        this.authController(err);
      }
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
  mounted() {
    this.button = this.$refs.button;
    this.dropwdown = this.$refs.dropwdown;
    document.addEventListener("click", (e) => {
      if (!this.dropwdown.contains(e.target)) {
        this.showDropdown = false;
      }
    });
  },
  created() {
    this.active = this.$store.state.userData.locale
  },
  beforeDestroy() {
    document.removeEventListener("click", () => {
      this.showDropdown = false;
    });
  },
  watch: {
    activeLocale() {
      this.active = this.activeLocale;
    },
  },
};
</script>
