<template>
  <div class="px-12 max-lg:px-8 max-md:px-5 max-sm:px-4 max-sm:my-8">
    <div class="mx-auto">
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10 gap-10 max-sm:gap-8"
      >
        <router-link to="/cv/0" class="h-full">
          <card
            data-v-step="0"
            classcard="w-32 -ml-10 "
            :img="require('@/assets/img/total-resume.svg')"
            :title="$t('general.index.card.totalCv')"
            :count="cvCount"
            :load="load"
          />
        </router-link>
        <router-link to="/project/0" class="h-full">
          <card
            data-v-step="1"
            classcard="w-14 ml-2  "
            :img="require('@/assets/img/open-position.svg')"
            :title="$t('general.index.card.openPositions')"
            :count="projectActiveCount"
            :load="load"
          />
        </router-link>
        <router-link to="/users">
          <card
            data-v-step="2"
            classcard="w-12 ml-2"
            :img="require('@/assets/img/total-user.svg')"
            :title="$t('general.index.card.totalUser')"
            :count="userCount"
            :load="load"
          />
        </router-link>
      </div>
    </div>
    <div class="flex w-full items-center mt-14 max-md:flex-wrap">
      <div class="w-[70%] max-md:w-full">
        <cvChart data-v-step="3" />
      </div>
      <div class="w-[30%] border-l border-gray-200 pl-2 max-md:w-full">
        <positionChart data-v-step="4" />
      </div>
    </div>
    <tour
      :steps="steps"
      :moduleId="8"
      v-if="!$store.state.userData.tourModuleId.includes(8)"
    />
  </div>
</template>
<script>
import card from "./components/card.vue";
import cvChart from "./components/cvChart.vue";
import positionChart from "./components/positionChart.vue";
import { general } from "@/networking/urlmanager";
import { generaltour } from "@/tourmodel/tour.js";
import tour from "@/components/tour.vue";
export default {
  name: "general-page",
  components: {
    card,
    cvChart,
    positionChart,
    tour,
  },
  data() {
    return {
      cvCount: "",
      userCount: "",
      projectActiveCount: "",
      load: false,
      steps: generaltour,
    };
  },
  methods: {
    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(general.getTotalCount, {
          headers: {
            Authorization: `Bearer ${this.$store.state.userData.token}`,
          },
        })
        .then((res) => {
          this.cvCount = res.data.data[0][0].cvCount;
          this.userCount = res.data.data[1][0].userCount;
          this.projectActiveCount = res.data.data[2][0].projectActiveCount;
          this.load = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.load = false;
        });
    },
  },
  created() {
    this.getAll();
  },
};
</script>
