<template>
  <div
    class="my-10 w-full max-sm:my-8 px-12 max-lg:px-8 max-md:px-5 max-sm:px-4"
    @mousedown="projectFilter = false"
  >
    <div class="flex gap-5 max-sm:flex-col">
      <div>
        <button
          class="px-8 py-2 bgcol h-11 text-sm w-full text-white rounded"
          @click="$refs.addProject.show()"
          data-v-step="0"
        >
          <i class="fas fa-plus mr-2"></i>
          {{ $t("project.index.add") }}
        </button>
      </div>
    </div>

    <addProject ref="addProject" @refresh="getAll()" />
    <editlProject ref="editProject" :rowId="cvId" @refresh="getAll()" />
    <advertModal
      :rowId="rowId"
      ref="advert"
      :isEdit="isEdit"
      @refresh="getAll()"
    />

    <div class="tableOverflow scroltypey mt-5">
      <div class="tableWidth">
        <div class="tableContainer">
          <div class="tableHead" data-v-step="1">
            <ul class="tableUl text-sm">
              <li class="w-[10%]">
                {{ $t("project.index.table.positionNo") }}
              </li>
              <li class="w-[17%]">
                {{ $t("project.index.table.positionName") }}
              </li>
              <li class="w-[12%]">
                <button
                  class="flex items-center h-full gap-1"
                  @click="addDateOrder()"
                >
                  <p>{{ $t("project.index.table.openingDate") }}</p>
                  <img
                    v-if="sortIcon(1)"
                    class="w-4 h-4"
                    :src="sortIcon(1)"
                    alt="Sort icon"
                  />
                </button>
              </li>
              <li class="w-[13%]">
                <button
                  class="flex items-center h-full gap-1"
                  @click="endDateOrder()"
                >
                  {{ $t("project.index.table.closingDate") }}
                  <img
                    v-if="sortIcon(2)"
                    class="h-4 w-4"
                    :src="sortIcon(2)"
                    alt="Sort icon"
                  />
                </button>
              </li>
              <li class="w-[13%]">
                {{ $t("project.index.table.NumberOfCandidates") }}
              </li>
              <li class="w-[18%]">
                {{ $t("project.index.table.companyName") }}
              </li>
              <li class="w-[23%] flex justify-end">
                <div class="w-full tableSearch relative">
                  <input
                    v-model="search"
                    @keypress.enter="getSearch()"
                    type="text"
                    class="h-full w-full outline-none pl-3 placeholder:textcol font-light rounded-md text-black pr-16"
                    :placeholder="$t('project.index.table.search')"
                  />

                  <button class="px-3 textcol absolute right-0">
                    <i
                      @click="getSearch()"
                      class="fa-solid fa-magnifying-glass"
                    ></i>
                  </button>

                  <button
                    @mousedown.stop="projectFilter = !projectFilter"
                    class="absolute right-9"
                  >
                    <div class="relative group flex justify-center">
                      <img
                        data-v-step="6"
                        src="@/assets/cv/filteredit.svg"
                        alt=""
                        class="w-5 object-contain"
                      />
                      <tooltip
                        :title="$t('project.index.table.tooltip.filterButton')"
                        tipClass=" z-50 !mr-[22px]  top-6 w-[130px] max-sm:!-right-[40px]"
                        rotatetopClass=" ml-96"
                        :top="true"
                      />
                    </div>
                  </button>

                  <div
                    @mousedown.stop="projectFilter = true"
                    :class="{
                      'invisible opacity-0 transition-all ease-in duration-100':
                        !projectFilter,
                    }"
                    class="w-full z-50 rounded mt-1 px-2 text-xs absolute bg-white top-full border border-gray-300 text-black shadow-md"
                  >
                    <button
                      v-for="(item, index) in projectState"
                      :key="index"
                      @click="getFilter(item.id)"
                      :class="{ 'text-blue-700': item.active }"
                      class="w-full py-2 border-b border-gray-100 text-left"
                    >
                      {{ item.name }}
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="tableBody scroltype">
            <div class="tableBodyContainer">
              <ul
                v-for="(item, index) in List"
                :key="index"
                class="tableBodyUl"
              >
                <li class="w-[10%] py-2.5 flex items-center">
                  #{{ item.id }}
                  <!-- {{ currentPage * 15 + (index + 1) }} -->
                </li>
                <div
                  @click="projectUserRedirection(item)"
                  class="w-[17%] py-2.5"
                >
                  <div
                    v-if="isContentOverflow(formatText(item.projectName, 25))"
                    class="relative group"
                  >
                    <div class="truncate text-ellipsis">
                      {{ formatText(item.projectName, 25) }}
                    </div>
                    <tooltip
                      :title="item.projectName"
                      tipClass="z-50 !top-6 w-[160px] !-right-2 text-center"
                      :top="true"
                    />
                  </div>
                  <div v-else>
                    {{ formatText(item.projectName, 25) }}
                  </div>
                </div>

                <div
                  @click="projectUserRedirection(item)"
                  class="w-[12%] py-2.5 ml-1 block text-xs"
                >
                  {{ getDate(item.addDate) }}
                </div>
                <div
                  @click="projectUserRedirection(item)"
                  class="w-[13%] py-2.5 ml-1 block text-xs"
                >
                  {{ getDate(item.endDate) }}
                </div>
                <div
                  @click="projectUserRedirection(item)"
                  class="w-[13%] py-2.5 flex items-center"
                >
                  <p
                    class="border border-[#182451] py-1 w-24 text-xs text-center rounded"
                  >
                    {{ item.applicantCount }}
                  </p>
                </div>

                <div
                  @click="projectUserRedirection(item)"
                  class="w-[22%] py-2.5 truncate text-ellipsis relative group pr-10"
                >
                  {{ item.companyName }}
                </div>

                <li
                  data-v-step="2"
                  class="w-[19%] flex justify-end pl-10 gap-10"
                >
                  <div>
                    <select
                      class="border border-[#182451] rounded"
                      v-if="!item.changeStatus"
                      v-model="item.temporaryStateId"
                      @change="onStatusChange($event, item)"
                    >
                      <option
                        v-for="(item, index) in projectState"
                        :key="index"
                        :value="item.id"
                        v-show="item.id != 0"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>

                  <div class="flex gap-5 justify-center">
                    <button
                      data-v-step="3"
                      @click="
                        () => ((cvId = item.id), $refs.editProject.show())
                      "
                      class="flex items-center h-full"
                    >
                      <div class="relative group flex justify-center">
                        <i class="fa-solid fa-pen-to-square text-base"></i>
                        <tooltip
                          :title="
                            $t('project.index.table.tooltip.positionEdit')
                          "
                          tipClass="z-50 !top-6 w-[160px] -right-3"
                          rotateClass="ml-[120px]"
                          :top="true"
                        />
                      </div>
                    </button>

                    <button
                      @click="
                        () => (
                          ((rowId = item.id), (isEdit = item.isEdit)),
                          $refs.advert.show()
                        )
                      "
                      class="flex justify-center items-center text-lg"
                    >
                      <div class="relative group flex justify-center">
                        <i class="fa-solid fa-newspaper"></i>
                        <!-- :title="
                            item.isEdit == 2
                              ? $t('project.index.table.tooltip.positionDetail')
                              : $t('project.index.table.tooltip.positionCreate')
                          " -->
                        <tooltip
                          :title="
                            item.isEdit == 2
                              ? $t(
                                  'project.components.add.advertAddButtonList.advertAdd'
                                )
                              : $t(
                                  'project.components.add.advertAddButtonList.view'
                                )
                          "
                          tipClass="z-50 w-[100px] !top-6 "
                          :top="true"
                        />
                      </div>
                    </button>

                    <div
                      @click="projectUserRedirection(item)"
                      data-v-step="4"
                      class="flex items-center h-full"
                    >
                      <div class="relative group flex justify-center">
                        <i class="fa-solid fa-magnifying-glass text-base"></i>
                        <tooltip
                          :title="
                            $t('project.index.table.tooltip.positionDetail')
                          "
                          tipClass="z-50 !top-6 w-[160px] !-right-2 text-center"
                          rotateClass="ml-[130px]"
                          :top="true"
                        />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <tableLoader :load="load" :length="List.length" colspan="7" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <tour
      :steps="steps"
      :moduleId="3"
      v-if="!$store.state.userData.tourModuleId.includes(3)"
    />
    <pagination
      v-model="currentPage"
      :pageCount="pageCount"
      :totalRowCount="totalCount"
    />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";
import tableLoader from "@/components/table-loader.vue";

import addProject from "./components/add.vue";
import editlProject from "./components/edit.vue";
import advertModal from "./advert/index.vue";

import { project } from "@/networking/urlmanager";
import { projecttour } from "@/tourmodel/tour.js";
import moment from "moment";

import tour from "@/components/tour.vue";
import tooltip from "@/components/tooltip.vue";

export default {
  name: "project-page",
  components: {
    pagination,
    tableLoader,
    addProject,
    editlProject,
    tour,
    tooltip,
    advertModal,
  },
  data() {
    return {
      temporaryStateId: null,
      load: false,
      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      projectFilter: false,
      selectedFilter: 0,
      projectState: this.$t("projectState"),
      steps: projecttour,
      rowId: 0,
      isEdit: 2,

      sort: 0,

      cvId: "",
      List: [],
    };
  },
  methods: {
    formatText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async projectUserRedirection(item) {
      await this.$store.commit("setStateId", item.stateId);

      this.$router.push({
        path: `/project/0/user`,
        query: {
          id: item.id,
          projectName: item.projectName,
        },
      });
    },
    getSearch() {
      this.currentPage = 0;
      this.getAll();
    },
    addDateOrder() {
      if (this.sort >= 2) {
        this.sort = 0;
      } else {
        this.sort++;
      }
      this.getAll();
    },

    endDateOrder() {
      if (this.sort < 3 || this.sort >= 5) {
        this.sort = 3;
      } else {
        this.sort = this.sort === 4 ? 0 : 4;
      }
      this.getAll();
    },
    getAll() {
      if (this.selectedFilter !== 0) {
        this.getFilter(this.selectedFilter);
        return;
      }

      this.load = true;
      this.List = [];
      this.axios
        .get(project.getAll, {
          params: {
            page: this.$route.params.currentPage,
            search: this.search,
            sort: this.sort,
          },
        })
        .then((res) => {
          const { totalCount, detail, pageCount } = res.data.data;
          this.List = detail.map((item) => ({
            ...item,
            temporaryStateId: item.stateId,
          }));
          this.totalCount = totalCount;
          this.pageCount = pageCount;
        })
        .catch((err) => {
          console.error(err);
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getFilter(val) {
      this.projectState.forEach((item) => {
        item.active = item.id === val;
      });
      this.projectFilter = false;
      this.selectedFilter = val;
      if (val !== 0) {
        this.load = true;
        this.List = [];
        this.axios
          .get(project.getFilter, {
            params: {
              page: this.currentPage,
              search: this.search,
              stateId: val,
            },
          })
          .then((res) => {
            const { totalCount, detail, pageCount } = res.data.data;
            this.totalCount = totalCount;
            this.pageCount = pageCount;
            this.List = detail.map((item) => ({
              ...item,
              temporaryStateId: item.stateId,
            }));
          })
          .catch((err) => {
            console.error(err);
            this.authController(err);
          })
          .finally(() => {
            this.load = false;
          });
      } else {
        this.getAll();
      }
    },
    isContentOverflow(content, val) {
      return content.length > val;
    },
    deleteItem(item) {
      if (item.stateId != 3) {
        this.verifiedMessage().then((result) => {
          if (result.isConfirmed) {
            this.axios
              .post(project.delete, {
                rowId: item.id,
                projectName: item.projectName,
                stateId: item.stateId,
              })
              .then(() => {
                this.successMessage();
                this.getAll();
              })
              .catch((err) => {
                const errorMessage = err.response
                  ? err.response.data.message
                  : err.message;
                this.authController(errorMessage);
              });
          }
        });
      } else {
        this.warningMesage(this.$t("project.warning.completedPosition"));
      }
    },

    onStatusChange(event, item) {
      const newStateId = event.target.value;
      this.verifiedMessage(
        "'" + item.projectName + "' " + this.$t("project.warning.chanceStatus"),
        this.$t("verifiedMessage.yesChance")
      ).then((result) => {
        if (result.isConfirmed) {
          this.applyStatusChange(item, newStateId);
        } else {
          item.temporaryStateId = item.stateId;
        }
      });
    },
    applyStatusChange(item, newStateId) {
      item.changeStatus = true;
      this.axios
        .post(project.changeStatus, {
          rowId: item.id,
          stateId: newStateId,
          projectName: item.projectName,
        })
        .then(() => {
          item.stateId = newStateId;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          item.changeStatus = false;
        });
    },

    getDate(val) {
      // internationalization
      if (val) return moment(val).format("LL");
      else return this.$t("project.index.noDate");
    },
    sortIcon(sortType) {
      const icons = {
        1: require("@/assets/project/filteringU.svg"),
        2: require("@/assets/project/filteringD.svg"),
        3: require("@/assets/project/filteringU.svg"),
        4: require("@/assets/project/filteringD.svg"),
      };
      if (
        (sortType === 1 && this.sort < 3) ||
        (sortType === 2 && this.sort >= 3)
      ) {
        return icons[this.sort];
      }
      return null;
    },
  },
  created() {
    this.getAll();
  },
  mounted() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    "$i18n.locale"() {
      this.projectState = this.$t("projectState");
    },
    search: function (newSearch) {
      if (newSearch.length === 0) {
        this.getAll();
      }
    },
  },
};
</script>
