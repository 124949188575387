<template>
  <div class="w-full">
    <multiselect
      :options="filterList"
      :value="getList()"
      @select="onSelect"
      @remove="onRemove"
      :multiple="true" 
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :internal-search="false"
      @search-change="asyncFind"
      placeholder=""
      label="name"
      track-by="id"
      :selectLabel="$t('globalComponents.dev.departmanSelect.add')"
      :deselectLabel="$t('globalComponents.dev.departmanSelect.delete')"
      :loading="load"
      :preselect-first="true"
    >
      <template v-slot:selection="{ values }">
        {{ values.length }}
        {{ $t("globalComponents.dev.departmanSelect.sectionChosen") }}
      </template>
    </multiselect>
  </div>
</template>

<script>
import multiselect from "vue-multiselect";
import { utility } from "@/networking/urlmanager";
import Fuse from "fuse.js";

export default {
  props: ["value", "isEdit"],
  components: {
    multiselect,
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      selectedList: [],
      List: [],
      filterList: [],
      load: false,
      fuse: null,
    };
  },
  methods: {
    onSelect(val) {
      if (!this.selectedList) this.selectedList = [];
      
      const findIndex = this.selectedList.findIndex(item => item.id === val.id);
      if (findIndex > -1) {
        const item = this.selectedList[findIndex];
        if (this.isEdit && item.status === 3 && item.rowId) {
          this.selectedList[findIndex].status = 1;
        }
        if (item.status !== 1) {
          this.selectedList[findIndex].status = 2;
        }
      } else {
        const tag = {
          id: val.id,
          name: val.name,
          status: 2,
        };
        this.selectedList.push(tag);
      }
    },
    onRemove(val) {
      if (!this.selectedList) return;
      
      const findIndex = this.selectedList.findIndex(r => r.id === val.id);
      if (findIndex === -1) return;

      if (this.isEdit && this.selectedList[findIndex].rowId) {
        this.selectedList[findIndex].status = 3;
      } else {
        this.selectedList.splice(findIndex, 1);
      }
    },
    getList() {
      return Array.isArray(this.selectedList) 
        ? this.selectedList.filter(r => r.status !== 3)
        : [];
    },
    asyncFind(val) {
      if (!this.fuse) return;

      if (!val) {
        this.filterList = [...this.List];
        return;
      }

      const results = this.fuse.search(val);
      this.filterList = results.map(result => result.item);
    },
    async getAll() {
      try {
        this.load = true;
        const response = await this.axios.get(utility.getDepartmentAll);
        this.List = response.data.data || [];
        this.filterList = [...this.List];

        const options = {
          keys: ["name"],
          includeScore: false,
          threshold: 0.4,
        };
        this.fuse = new Fuse(this.List, options);
      } catch (error) {
        console.error("Error loading department data:", error);
      } finally {
        this.load = false;
      }
    },
  },
  async created() {
    await this.getAll();
    this.selectedList = this.value || [];
  },
  watch: {
    selectedList: {
      deep: true,
      handler(val) {
        if (!Array.isArray(val)) return;
        
        this.filterList.sort((a, b) => {
          const indexA = val.findIndex(r => r.id === a.id);
          const indexB = val.findIndex(r => r.id === b.id);
          return indexB - indexA;
        });
        this.$emit("change", val);
      }
    },
    value: {
      immediate: true,
      handler(val) {
        this.selectedList = val || [];
      }
    }
  }
};
</script>