<template>
  <div v-if="!isLoading && List.length > 0" class="mt-2 overflow-y-auto" style="height: calc(100% - 110px)">
    <div class="flex justify-between gap-2 py-3 items-center bg-white border-b border-gray-200 px-3"
      v-for="(item, index) in List" :key="index">
      <h6 class="text-sm flex justify-between w-full items-center">
        <div class="flex-1">
          <div class="flex items-center gap-2">
            <i class="far fa-file-alt text-gray-400"></i>
            {{ formatText(item.fileName) }}
          </div>

          <p class="text-xs text-gray-500 mt-1">
            {{ item.addDate | getDate }}
          </p>
        </div>
        
        <span class="flex gap-5 items-center">
          <span class="bg-[#fff3cd] text-[#856404] text-xs px-2 py-1 rounded-md inline-flex items-center gap-1.5">
            <i class="fas fa-sync-alt animate-spin text-[10px]"></i>
            {{ $t("cv.components.cvListModal.processing") }}
          </span>
          <button v-if="isPast24Hours(item.addDate)" 
                  @click="deleteItem(item)"
                  class="text-gray-400 hover:text-red-500 transition-colors p-1 rounded-full hover:bg-red-50">
            <i class="fa-solid fa-trash-can text-[14px]"></i>
          </button>
        </span>
      </h6>
    </div>
  </div>
</template>

<script>
import { cv } from "@/networking/urlmanager";
import moment from "moment";

export default {
  name: "loading-cv",
  props: ["load"],
  data() {
    return {
      List: [],
      refreshInterval: null,
      isLoading: false
    };
  },
  watch: {
    load: {
      immediate: true,
      handler(newVal) {
        this.isLoading = newVal;
      }
    }
  },
  methods: {
    isPast24Hours(addDate) {
      if (!addDate) return false;
      return moment().diff(moment(addDate), 'hours') > 24;
    },
    async getAll(loading = true) {
      try {
        if(this.load)return;

        this.isLoading = loading;
        this.$emit("load", loading);

        const res = await this.axios.get(cv.getCvTrain, {
          params: { stateId: 1 }
        });

        this.List = res.data.data.map((r) => ({
          id: r.id,
          data: this.listFormatController(r.completion),
          originalFile: r.originalFile,
          stateId: r.stateId,
          fileName: r.fileName,
          addDate: r.addDate,
          isView: 1
        }));
        
        this.$emit("chanceList", this.List);
        this.$store.commit("extractCvList", this.List);
        
        this.isLoading = false;
        this.$emit("load", false);
      } catch (err) {
        console.log(err);
        this.authController(err);
        this.isLoading = false;
        this.$emit("load", false);
      }
    },
    async deleteItem(item) {
      try {
        const data = await this.verifiedMessage();
        if (data.isConfirmed) {
          await this.axios.post(cv.deleteTrain, {
            rowId: item.id
          });
          await this.getAll();
        }
      } catch (err) {
        console.log(err);
        this.authController(err);
      }
    }
  },
  filters: {
    getDate(val) {
      if (val) {
        moment.locale('tr');
        return moment(val).format("LLL");
      }
      return this.$t("cvSimilar.index.date");
    }
  },
  mounted() {
    moment.locale('tr');
    this.getAll();
    this.refreshInterval = setInterval(() => {
      this.getAll(false);
    }, 30000);
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }
};
</script>