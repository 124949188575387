<template>
  <div class="w-full mt-10">
    <editItem
      ref="editItem"
      :isEdit="isEdit"
      :Detail="selected"
      @refresh="getAll()"
      @loadChance="loading = !loading"
    />
    <addItem
      ref="addItem"
      :isEdit="isEdit"
      @refresh="getAll()"
      @loadChance="loading = !loading"
    />
    <topBar
      :title="$t('cv.profile.personalInformation.index.topBarTitle')"
      @add="$refs.addItem.show()"
      :addText="$t('cv.profile.personalInformation.index.topBarText')"
    />

    <div v-if="isContent && !loading" class="w-full grid grid-cols-2 gap-x-10">
      <div
        v-if="$store.state.extractDetail.dateBirth"
        class="w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"
      >
        <card
          :title="$t('cv.profile.personalInformation.index.dateOfBirth')"
          :sub="$store.state.extractDetail.dateBirth"
          :img="require('@/assets/cv/carbon_user.svg')"
          @deleteItem="isEdit ? deleteItem(1) : deleteStoreItem(1)"
          @editItem="
            editItem({
              id: 1,
              item: $store.state.extractDetail.dateBirth,
              type: 'date',
              title: $t(
                'cv.profile.personalInformation.index.dateOfBirthTitle'
              ),
              formatDate: $store.state.extractDetail.formatDateBirth,
            })
          "
        />
      </div>

      <div
        v-if="
          $store.state.extractDetail.country &&
          $store.state.extractDetail.country != 'Unknown'
        "
        class="w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"
      >
        <card
          :img="require('@/assets/cv/carbon_user.svg')"
          :title="$t('cv.profile.personalInformation.index.country')"
          :sub="$store.state.extractDetail.country"
          @deleteItem="isEdit ? deleteItem(2) : deleteStoreItem(2)"
          @editItem="
            editItem({
              id: 2,
              item: $store.state.extractDetail.country,
              type: 'multiSelect',
              getOptions: getAllCountry,
              title: $t('cv.profile.personalInformation.index.countryText'),
            })
          "
        />
      </div>

      <div
        v-if="
          $store.state.extractDetail.city &&
          $store.state.extractDetail.city != 'Unknown'
        "
        class="w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"
      >
        <card
          :img="require('@/assets/cv/carbon_user.svg')"
          :title="$t('cv.profile.personalInformation.index.city')"
          :sub="$store.state.extractDetail.city"
          @deleteItem="isEdit ? deleteItem(3) : deleteStoreItem(3)"
          @editItem="
            editItem({
              id: 3,
              item: $store.state.extractDetail.city,
              type: 'multiSelect',
              getOptions: getAllCity,
              title: $t('cv.profile.personalInformation.index.cityText'),
            })
          "
        />
      </div>

      <div
        v-if="$store.state.extractDetail.driverLicense"
        class="w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"
      >
        <card
          :title="$t('cv.profile.personalInformation.index.drivingLicence')"
          :sub="
            $store.state.extractDetail.driverLicense
              ? $store.state.extractDetail.driverLicense == 1
                ? $t('cv.profile.personalInformation.index.drivingLicenceYes')
                : $store.state.extractDetail.driverLicense == 2
                ? $t('cv.profile.personalInformation.index.drivingLicenceNo')
                : ' '
              : ''
          "
          :img="require('@/assets/cv/carbon_user.svg')"
          @deleteItem="isEdit ? deleteItem(6) : deleteStoreItem(6)"
          @editItem="
            editItem({
              id: 6,
              item: $store.state.extractDetail.driverLicense,
              type: 'select',
              title: $t('cv.profile.personalInformation.index.drivingLicence'),
              selectList: [
                {
                  name: $t(
                    'cv.profile.personalInformation.index.drivingLicenceYes'
                  ),
                  value: 1,
                },
                {
                  name: $t(
                    'cv.profile.personalInformation.index.drivingLicenceNo'
                  ),
                  value: 2,
                },
              ],
              commitName: 'setEditPlaceBirth',
            })
          "
        />
      </div>

      <div
        v-if="
          $store.state.extractDetail.placeBirth &&
          $store.state.extractDetail.placeBirth != 'null'
        "
        class="w-full border-b border-b-[#344ba045] py-2 mb-3 border-dotted avoid-page-break"
      >
        <card
          :title="$t('cv.profile.personalInformation.index.placeOfBirth')"
          :sub="$store.state.extractDetail.placeBirth"
          :img="require('@/assets/cv/carbon_user.svg')"
          @deleteItem="isEdit ? deleteItem(4) : deleteStoreItem(4)"
          @editItem="
            editItem({
              id: 4,
              item: $store.state.extractDetail.placeBirth,
              type: 'text',
              title: $t(
                'cv.profile.personalInformation.index.placeOfBirthText'
              ),
            })
          "
        />
      </div>
    </div>

    <dataLoader
      :load="loading"
      :isContent="isContent"
      :title="$t('cv.profile.personalInformation.index.dataLoader')"
    />
  </div>
</template>
<script>
import card from "../components/card.vue";
import editItem from "./components/edit.vue";
import addItem from "./components/add.vue";

import topBar from "../components/topBar.vue";
import dataLoader from "../components/dataLoader.vue";

import { cv, utility } from "@/networking/urlmanager.js";

export default {
  name: "prsonalInformation-components",
  props: ["load", "isEdit"],
  components: {
    card,
    editItem,
    addItem,
    topBar,
    dataLoader,
  },
  data() {
    return {
      loading: false,
      selected: "",
    };
  },
  methods: {
    getAll() {
      this.loading = true;
      this.axios
        .get(cv.getInformation + "?cvId=" + this.$route.params.id)
        .then((res) => {
          this.$store.commit("setPersonalInformation", res.data.data[0]);
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getAllCity() {
      const response = await this.axios.get(utility.getCityAll);
      return response.data.data;
    },
    async getAllCountry() {
      const response = await this.axios.get(utility.getCountryAll);
      return response.data.data;
    },
    deleteItem(val) {
      this.verifiedMessage().then((result) => {
        if (result.isConfirmed) {
          const data = this.addValue(val);
          this.axios
            .post(cv.deleteInformation, {
              cvId: this.$route.params.id,
              ...data,
            })
            .then((res) => {
              this.successMessage(res.data.message);
              this.getAll();
            })
            .catch((err) => {
              const errorMessage = err.response
                ? err.response.data.message
                : err.message;
              this.authController(errorMessage);
            });
        }
      });
    },
    deleteStoreItem(val) {
      this.verifiedMessage().then((acc) => {
        if (acc.isConfirmed) {
          this.$store.commit("setEditPersonalInformation", {
            id: val,
            value: "",
          });
        }
      });
    },
    editItem(val) {
      this.$refs.editItem.show();
      this.selected = val;
    },
    addValue(val) {
      switch (val) {
        case 1:
          return { dateBirth: 1 };
          break;
        case 2:
          return { country: 1 };
          break;
        case 3:
          return { city: 1 };
          break;
        case 4:
          return { placeBirth: 1 };
          break;
        case 5:
          return { email: 1 };
          break;
        case 6:
          return { driverLicense: 1 };
          break;
        default:
          return false;
          break;
      }
    },
  },
  computed: {
    isContent() {
      if (
        this.$store.state.extractDetail.dateBirth ||
        this.$store.state.extractDetail.city ||
        this.$store.state.extractDetail.country ||
        this.$store.state.extractDetail.driverLicense ||
        this.$store.state.extractDetail.placeBirth ||
        this.$store.state.extractDetail.email
      ) {
        return true;
      } else return false;
    },
  },
};
</script>
