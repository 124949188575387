<template>
  <div>
    <multiselect
      v-model="selectedOption"
      :options="options"
      :multiple="true"
      :taggable="true"
      @input="updateSelectedEmails"
      @tag="addNewEmail"
      :placeholder="
        $t(
          'project.projectUser.components.shortList.components.steps.userAddSelected.placeholder'
        )
      "
    >
    </multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { technicalInterviewGeneral } from "@/networking/urlmanager";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      selectedOption: [],
      options: [],
    };
  },
  created() {
    this.fetchOptions();
  },
  methods: {
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    fetchOptions() {
      this.axios
        .get(technicalInterviewGeneral.getAttendeeMails)
        .then((response) => {
          this.options = response.data.data;
        })
        .catch((error) => {
          console.error("Seçenekler çekilirken hata oluştu:", error);
          this.$toast.error(
            this.$t(
              "project.projectUser.components.shortList.components.steps.userAddSelected.optionsNotLoaded"
            )
          );
        });
    },
    updateSelectedEmails() {
      this.$emit("emails-selected", this.selectedOption);
    },
    addNewEmail(newEmail) {
      if (this.isValidEmail(newEmail)) {

        this.options = [...this.options, newEmail]; 
        this.selectedOption = [...this.selectedOption, newEmail];   
        this.updateSelectedEmails();      
      } else {
        this.$toast.error(
          this.$t(
            "project.projectUser.components.shortList.components.steps.userAddSelected.invalidEmail"
          )
        );
      }
    },
  },
};
</script>
